import {
  FlowSolutionState,
  ShapeExecutionState,
  StatusExecuteColor,
} from 'modules/project-flow-solution/board-ui/enums';
import { FlowSolutionExecution } from 'modules/project-flow-solution/types';
import { CSSProperties, useEffect, useState } from 'react';

const getStylesByState = (state: ShapeExecutionState | ''): CSSProperties | undefined => {
  if (state === ShapeExecutionState.ERROR) {
    return { backgroundColor: '#ff707e', borderColor: StatusExecuteColor.ERROR };
  }

  if (state === ShapeExecutionState.COMPLETED) {
    return { backgroundColor: 'rgb(167 231 185)', borderColor: StatusExecuteColor.SUCCESS };
  }

  if (state === ShapeExecutionState.PROCESSING) {
    return { backgroundColor: '#FFD580', borderColor: StatusExecuteColor.RUNNING };
  }

  if (state === ShapeExecutionState.PENDING) {
    return { backgroundColor: '#b2b2b2' };
  }

  if (state === ShapeExecutionState.SKIPPED) {
    return { backgroundColor: '#6E91B2' };
  }

  if (state === ShapeExecutionState.ABANDONED) {
    return { backgroundColor: '#D4A76A' };
  }
  return undefined;
};

const useHookStyles = (shapeSelection: string, currentShapeId: string, executionDetails: FlowSolutionExecution) => {
  const [style, setStyle] = useState<CSSProperties | undefined>(undefined);
  useEffect(() => {
    const { state } = executionDetails?.shapeStatuses?.find((it) => it.shapeId === currentShapeId) || { state: '' };
    const styles = getStylesByState(state);
    if (
      [
        FlowSolutionState.COMPLETED,
        FlowSolutionState.EMPTY,
        FlowSolutionState.ERROR,
        FlowSolutionState.STOPPED,
      ].includes(executionDetails.currentState)
    ) {
      if (shapeSelection === currentShapeId) {
        setStyle({ outline: '#b2b2b2 solid 10px', ...styles });
      } else {
        setStyle({ outline: 'none', ...styles });
      }
    } else {
      setStyle(styles);
    }
  }, [currentShapeId, shapeSelection, executionDetails.shapeStatuses, executionDetails.currentState]);
  return style;
};

export default useHookStyles;
