import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { Fragment, memo, useContext, useEffect, useMemo, useState } from 'react';
import Select from 'modules/shared/components/Select2';
import { Button, Box, Grid, Typography, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { useStyles } from './styles';
import { INPUT, OUTPUT } from '../constants';
import { WizardContext, WizardHandlersContext } from '../wizard-context/context';
import { BlendConfig } from '../types';

const MODAL_ID = 'blend-config-modal';

const FORM_ID = 'blend-config-modal-form';

const Modal = ({ editable = false }) => {
  const classes = useStyles();

  const { isBlendConfigModalOpen: isOpen, stepData } = useContext(WizardContext);
  const { closeBlendConfigModal, updateStepConfig } = useContext(WizardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);

  const [config, setConfig] = useState<BlendConfig>({
    processMatchMany: false,
    returnNoMatch: false,
    matchingRules: [{ source: '', target: '' }],
  });

  const { shapes } = stepData!.config.board;

  useEffect(() => {
    if (isOpen) {
      setConfig(stepData!.config.blendConfig!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();

    updateStepConfig({
      blendConfig: config,
    });

    closeBlendConfigModal();
  };

  const disabled = insidePreview;
  const startShape = useMemo(() => shapes.find((s) => s.type === INPUT), [shapes])!;

  const sourceCols = useMemo(
    () => startShape?.metadata.columns?.map((c) => ({ label: c, value: c })) || [],
    [startShape],
  );

  const endShape = useMemo(() => shapes.find((s) => s.type === OUTPUT), [shapes])!;

  const targetCols = useMemo(
    () => endShape?.metadata.columns?.map((c) => ({ label: c.name, value: c.name })) || [],
    [endShape],
  );

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={closeBlendConfigModal}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={closeBlendConfigModal}>
        Blend configuration
      </Title>
      <Content className={classes.dialogContent}>
        <Box component="form" id={FORM_ID} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {config.matchingRules.map((rule, idx, { length }) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={`${rule.source}-${rule.target}`}>
                <Grid item xs={disabled ? 4 : 3} style={{ display: 'flex', alignItems: 'center' }}>
                  {idx === 0 ? <Typography variant="subtitle1">Match on</Typography> : ''}
                </Grid>
                <Grid item xs={4}>
                  {!disabled ? (
                    <Select
                      id={`source-${idx}`}
                      placeholder="Source"
                      value={[rule.source]}
                      options={sourceCols}
                      disableClear
                      required
                      disabled={insidePreview || !editable}
                      onChange={(val) => {
                        setConfig((prevConfig) => {
                          return {
                            ...prevConfig,
                            matchingRules: prevConfig.matchingRules.map((r, i) => {
                              if (i === idx) {
                                return {
                                  ...r,
                                  source: val[0],
                                };
                              }

                              return r;
                            }),
                          };
                        });
                      }}
                    />
                  ) : (
                    <Typography variant="subtitle1">{rule.source}</Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!disabled ? (
                    <Select
                      id={`target-${idx}`}
                      placeholder="Target"
                      value={[rule.target]}
                      options={targetCols}
                      disableClear
                      required
                      disabled={insidePreview || !editable}
                      onChange={(val) => {
                        setConfig((prevConfig) => {
                          return {
                            ...prevConfig,
                            matchingRules: prevConfig.matchingRules.map((r, i) => {
                              if (i === idx) {
                                return {
                                  ...r,
                                  target: val[0],
                                };
                              }

                              return r;
                            }),
                          };
                        });
                      }}
                    />
                  ) : (
                    <Typography variant="subtitle1">{rule.target}</Typography>
                  )}
                </Grid>
                {!insidePreview
                  ? !disabled && (
                      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {idx + 1 === length ? (
                          <IconButton
                            onClick={() => {
                              setConfig((prev) => ({
                                ...prev,
                                matchingRules: prev.matchingRules.concat({ source: '', target: '' }),
                              }));
                            }}
                            title="Add More"
                          >
                            <Add />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => {
                              setConfig((prev) => ({
                                ...prev,
                                matchingRules: prev.matchingRules.filter((_, i) => i !== idx),
                              }));
                            }}
                            title="Remove"
                          >
                            <Remove />
                          </IconButton>
                        )}
                      </Grid>
                    )
                  : null}
              </Fragment>
            ))}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    readOnly={disabled}
                    checked={config.processMatchMany}
                    disabled={insidePreview || !editable}
                    onClick={() => {
                      setConfig((prev) => ({ ...prev, processMatchMany: !prev.processMatchMany }));
                    }}
                  />
                }
                label="Process Match Many"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    readOnly={disabled}
                    size="small"
                    checked={config.returnNoMatch}
                    disabled={insidePreview || !editable}
                    onClick={() => {
                      setConfig((prev) => ({ ...prev, returnNoMatch: !prev.returnNoMatch }));
                    }}
                  />
                }
                label="Return No Match"
              />
            </Grid>
          </Grid>
        </Box>
      </Content>
      {!insidePreview && editable && (
        <Actions>
          <Button variant="contained" color="primary" type="submit" form={FORM_ID} disabled={false}>
            Set
          </Button>
        </Actions>
      )}
    </Dialog>
  );
};

export default memo(Modal);
