import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import Select from 'modules/shared/components/Select2';
import { Button, Box } from '@mui/material';
import { useStyles } from './styles';
import Config from '../Config';
import { RuleTypes, RuleTypesListOptions, ConditionTypes, ConditionTypesListOptions } from '../../constants';
import { ruleConfigPerType, conditionConfigPerType } from '../constants';
import { WizardContext, WizardHandlersContext } from '../../wizard-context/context';

const MODAL_ID = 'update-transformation-modal';
const FORM_ID = 'update-transormation-modal-form';

const Modal = () => {
  const classes = useStyles();

  const { isUpdateTransformationModalOpen: isOpen, editableTansformation, stepData } = useContext(WizardContext);
  const { closeUpdateTransformationModal, updateTransformation } = useContext(WizardHandlersContext);

  const [type, setType] = useState<RuleTypes | ConditionTypes | ''>('');
  const [config, setConfig] = useState({});

  const { shapes } = stepData!.config.board;

  const shape = useMemo(() => shapes?.find((s) => s.id === editableTansformation), [shapes, editableTansformation]);

  useEffect(() => {
    if (isOpen && shape) {
      setType(shape.metadata.type || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, shape]);

  useEffect(() => {
    if (shape) {
      const configPerType = ruleConfigPerType[type] || conditionConfigPerType[type];
      setConfig(type === shape.metadata.type ? shape.metadata.config : configPerType || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, shape]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (shape) {
      updateTransformation(editableTansformation, type as RuleTypes | ConditionTypes, config);
      closeUpdateTransformationModal();
    }
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={closeUpdateTransformationModal}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={closeUpdateTransformationModal}>
        Edit transformation configuration
      </Title>
      <Content className={classes.dialogContent}>
        <Box component="form" id={FORM_ID} onSubmit={handleSubmit}>
          <Select
            id="category"
            value={[type]}
            options={[...RuleTypesListOptions, ...ConditionTypesListOptions]}
            className={classes.select}
            disableClear
            onChange={(val) => {
              setType(val[0]);
            }}
            required
          />
          <Config type={type} config={config} setConfig={setConfig} />
        </Box>
      </Content>
      <Actions>
        <Button variant="contained" color="primary" type="submit" form={FORM_ID} disabled={false}>
          Set
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(Modal);
