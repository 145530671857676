import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: (props: { conditionType: 'if' | 'ifElse' }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: '2px solid',
    borderColor: props.conditionType === 'if' ? 'green' : 'orange',
    boxShadow: '0 8px 8px 0 rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    width: '220px',
    minHeight: '100px',
    overflowY: 'auto',
  }),
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '10px',
    boxSizing: 'border-box',
  },
  conditionTypeText: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: '16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px',
  },
  headerItemText: {
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
  },
  expandButton: {
    marginLeft: 'auto',
  },
  ruleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    maxHeight: '80px',
    overflowY: 'auto',
    padding: '5px',
    width: '100%',
  },
  conditionList: {
    flexGrow: 1,
    width: '100%',
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '5px 0',
  },
  headerItemMenuContainer: {
    position: 'absolute',
    top: '5%',
    right: '5%',
  },
  headerItemMenu: {
    width: '20px',
    height: '20px',
  },
  ruleText: {
    fontSize: 14,
    borderBottom: '1px solid #ddd',
    paddingBottom: 4,
    width: '100%',
    textAlign: 'center',
    wordWrap: 'break-word',
  },
}));
