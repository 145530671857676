export enum RuleTypes {
  Add = 'add',
  Case = 'case',
  Combine = 'combine',
  Concat = 'concat',
  DateAdd = 'dateAdd',
  DateSub = 'dateSub',
  Divide = 'divide',
  Equal = 'equal',
  EqualColumn = 'equalcolumn',
  Find = 'find',
  FormatDate = 'formatdate',
  Hash = 'hash',
  Insert = 'insert',
  Len = 'len',
  Mid = 'mid',
  Multiply = 'multiply',
  PadNum = 'padNum',
  Remove = 'remove',
  Replace = 'replace',
  ReplaceStr = 'replacestr',
  Right = 'right',
  Round = 'round',
  Slice = 'slice',
  Split = 'split',
  Subtract = 'subtract',
}

export enum ConditionTypes {
  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
  EqualCondition = 'equalCondition',
  NotEqual = 'notEqual',
  Contains = 'contains',
  DoesNotContain = 'doesNotContain',
  IsNull = 'isNull',
  IsNotNull = 'isNotNull',
  IsGreaterThan = 'isGreaterThan',
  IsLessThan = 'isLessThan',
  IsBetween = 'isBetween',
  LenGreaterThan = 'lenGreaterThan',
  LenLessThan = 'lenLessThan',
}

export const RuleTypesList = Object.entries(RuleTypes);
export const ConditionTypesList = Object.entries(ConditionTypes);

export const RuleTypesListOptions = RuleTypesList.map(([label, value]) => ({ label, value }));
export const ConditionTypesListOptions = ConditionTypesList.map(([label, value]) => ({ label, value }));

export const INPUT = 'input';
export const OUTPUT = 'output';
export const EDITABLE_COLUMNS = 'editable-columns';
export const CONDITION_CARD = 'condition';
export const TRANSFORMATION = 'transformation';
export const COMBINE_COLUMN = 'combine-value';
export const startConnectionDelimeter = '-start-';
export const endConnectionDelimeter = '-end-';

export const MAX_SCALE = 10;
export const MIN_SCALE = 0.5;
export const DEFAULT_CANVAS_SIZE = 5000;
export const INITIAL_BOARD_CONFIG = { scale: 6, x: 2000, y: 2000 };

export enum StepType {
  TRANSFORM = 'transformation',
  BLEND = 'blend',
  COMBINE = 'combine',
  ADD_COLUMNS = 'add',
  DELETE_COLUMNS = 'delete',
}

export const StepNames = {
  [StepType.TRANSFORM]: 'Transformation',
  [StepType.BLEND]: 'Blend',
  [StepType.ADD_COLUMNS]: 'Add Columns',
  [StepType.DELETE_COLUMNS]: 'Delete Columns',
  [StepType.COMBINE]: 'Combine',
};

export const EditableSteps = [StepType.BLEND];

export const INPUT_SHAPE_WIDTH = 250;
export const INPUT_SHAPE_HEIGHT = 300;

export enum Views {
  JSON = 'json',
  BOARD = 'board',
}
