import { InputLabel } from '@material-ui/core';
import Fields from '../Fields';

const BodyFields = ({ title, fields, onChange, onDelete, readOnly = false }) => {
  return (
    <>
      <InputLabel shrink>{title}</InputLabel>
      {Object.keys(fields).map((fieldId) => {
        return (
          <Fields
            key={fieldId}
            fieldKey={fields[fieldId].key}
            fieldValue={fields[fieldId].value}
            isDefault={fields[fieldId].default}
            id={fieldId}
            onChange={onChange}
            onDelete={onDelete}
            readOnly={readOnly}
          />
        );
      })}
    </>
  );
};

export default BodyFields;
