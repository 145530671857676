import { RuleTypes, ConditionTypes } from 'modules/digital-threads-mapping-wizard/constants';

export const ruleConfigPerType = {
  [RuleTypes.Add]: {
    val1: '',
  },
  [RuleTypes.Case]: {
    type: '',
  },
  [RuleTypes.Combine]: {
    delimiter: '',
  },
  [RuleTypes.Concat]: {
    type: '',
    value: '',
  },
  [RuleTypes.DateAdd]: {
    type: '',
    amount: '',
  },
  [RuleTypes.DateSub]: {
    date1: '',
    date2: '',
    returnType: '',
  },
  [RuleTypes.Divide]: {
    val1: '',
  },
  [RuleTypes.Equal]: {
    value: '',
  },
  [RuleTypes.EqualColumn]: {
    value: '',
  },
  [RuleTypes.Find]: {
    find: '',
  },
  [RuleTypes.FormatDate]: {
    format_from: '',
    format_to: '',
  },
  [RuleTypes.Hash]: {
    method: '',
    encode: '',
  },
  [RuleTypes.Insert]: {
    insertValue: '',
    index: '',
  },
  [RuleTypes.Len]: {
    value: '',
  },
  [RuleTypes.Mid]: {
    startChar: '',
    endChar: '',
  },
  [RuleTypes.Multiply]: {
    val1: '',
  },
  [RuleTypes.PadNum]: {
    paddingLength: '',
    PadItem: '',
  },
  [RuleTypes.Remove]: {
    type: '',
    noChar: '',
  },
  [RuleTypes.Replace]: {
    find: '',
    replace: '',
  },
  [RuleTypes.ReplaceStr]: {
    find: '',
    replace: '',
  },
  [RuleTypes.Right]: {
    noChar: '',
  },
  [RuleTypes.Round]: {
    noPlace: '',
  },
  [RuleTypes.Slice]: {
    start: '',
    end: '',
  },
  [RuleTypes.Split]: {
    delimiter: '',
    return: '',
  },
  [RuleTypes.Subtract]: {
    val1: '',
  },
};

export const conditionConfigPerType = {
  [ConditionTypes.StartsWith]: {
    value: '',
    rules: [],
  },
  [ConditionTypes.EndsWith]: {
    value: '',
    rules: [],
  },
  [ConditionTypes.EqualCondition]: {
    value: '',
    rules: [],
  },
  [ConditionTypes.NotEqual]: {
    value: '',
    rules: [],
  },
  [ConditionTypes.Contains]: {
    value: '',
    rules: [],
  },
  [ConditionTypes.DoesNotContain]: {
    value: '',
    rules: [],
  },
  [ConditionTypes.IsNull]: {
    rules: [],
  },
  [ConditionTypes.IsNotNull]: {
    rules: [],
  },
  [ConditionTypes.IsGreaterThan]: {
    value: '',
    rules: [],
  },
  [ConditionTypes.IsLessThan]: {
    value: '',
    rules: [],
  },
  [ConditionTypes.IsBetween]: {
    value1: '',
    value2: '',
    rules: [],
  },
  [ConditionTypes.LenGreaterThan]: {
    value: '',
    rules: [],
  },
  [ConditionTypes.LenLessThan]: {
    value: '',
    rules: [],
  },
};

const BASE_TEXT_FIELD = {
  type: 'text',
  filterValue: (val: string) => val,
};

const BASE_NUMBER_FIELD = {
  type: 'number',
  filterValue: (val: string) => val,
};
const BASE_DATE_FIELD = {
  type: 'date',
  filterValue: (val: Date | null) => val,
};

export const ruleFieldsConfigs = {
  [RuleTypes.Add]: {
    val1: BASE_NUMBER_FIELD,
  },
  [RuleTypes.Case]: {
    type: {
      isSelect: true,
      options: [
        { value: 'upper', label: 'Upper' },
        { value: 'lower', label: 'Lower' },
        { value: 'title', label: 'Title' },
      ],
    },
  },
  [RuleTypes.Combine]: {
    delimiter: BASE_TEXT_FIELD,
  },
  [RuleTypes.Concat]: {
    type: {
      isSelect: true,
      options: [
        { value: 'start', label: 'Start' },
        { value: 'end', label: 'End' },
      ],
    },
    value: BASE_TEXT_FIELD,
  },
  [RuleTypes.DateAdd]: {
    type: {
      isSelect: true,
      options: [
        { value: 'day', label: 'Day' },
        { value: 'month', label: 'Month' },
        { value: 'year', label: 'Year' },
        { value: 'minute', label: 'Minute' },
        { value: 'hour', label: 'Hour' },
        { value: 'week', label: 'Week' },
      ],
    },
    amount: BASE_NUMBER_FIELD,
  },
  [RuleTypes.DateSub]: {
    date1: BASE_DATE_FIELD,
    date2: BASE_DATE_FIELD,
    returnType: {
      isSelect: true,
      options: [
        { value: 'day', label: 'Day' },
        { value: 'week', label: 'Week' },
        { value: 'month', label: 'Month' },
        { value: 'year', label: 'Year' },
        { value: 'minute', label: 'Minute' },
        { value: 'hour', label: 'Hour' },
        { value: 'all', label: 'All' },
      ],
    },
  },
  [RuleTypes.Divide]: {
    val1: BASE_NUMBER_FIELD,
  },
  [RuleTypes.Equal]: {
    value: BASE_TEXT_FIELD,
  },
  [RuleTypes.EqualColumn]: {
    value: BASE_TEXT_FIELD,
  },
  [RuleTypes.Find]: {
    find: BASE_TEXT_FIELD,
  },
  [RuleTypes.FormatDate]: {
    format_from: BASE_TEXT_FIELD,
    format_to: BASE_TEXT_FIELD,
  },
  [RuleTypes.Hash]: {
    method: {
      isSelect: true,
      options: [
        { value: 'md5', label: 'MD5' },
        { value: 'sha256', label: 'SHA-256' },
        { value: 'sha1', label: 'SHA-1' },
      ],
    },
    encode: BASE_TEXT_FIELD,
  },
  [RuleTypes.Insert]: {
    insertValue: BASE_TEXT_FIELD,
    index: BASE_NUMBER_FIELD,
  },
  [RuleTypes.Len]: {
    value: BASE_TEXT_FIELD,
  },
  [RuleTypes.Mid]: {
    startChar: BASE_NUMBER_FIELD,
    endChar: BASE_NUMBER_FIELD,
  },
  [RuleTypes.Multiply]: {
    val1: BASE_NUMBER_FIELD,
  },
  [RuleTypes.PadNum]: {
    paddingLength: BASE_NUMBER_FIELD,
    PadItem: BASE_TEXT_FIELD,
  },
  [RuleTypes.Remove]: {
    type: BASE_TEXT_FIELD,
    noChar: BASE_NUMBER_FIELD,
  },
  [RuleTypes.Replace]: {
    find: BASE_TEXT_FIELD,
    replace: BASE_TEXT_FIELD,
  },
  [RuleTypes.ReplaceStr]: {
    find: BASE_TEXT_FIELD,
    replace: BASE_TEXT_FIELD,
  },
  [RuleTypes.Right]: {
    noChar: BASE_NUMBER_FIELD,
  },
  [RuleTypes.Round]: {
    noPlace: BASE_NUMBER_FIELD,
  },
  [RuleTypes.Slice]: {
    start: BASE_NUMBER_FIELD,
    end: BASE_NUMBER_FIELD,
  },
  [RuleTypes.Split]: {
    delimiter: BASE_TEXT_FIELD,
    return: {
      type: 'text',
      filterValue: (val) => val.replace(/[^0-9,]/g, ''),
    },
  },
  [RuleTypes.Subtract]: {
    val1: BASE_NUMBER_FIELD,
  },
};

export const conditionFieldsConfigs = {
  [ConditionTypes.StartsWith]: {
    value: BASE_TEXT_FIELD,
  },
  [ConditionTypes.EndsWith]: {
    value: BASE_TEXT_FIELD,
  },
  [ConditionTypes.EqualCondition]: {
    value: BASE_TEXT_FIELD,
  },
  [ConditionTypes.NotEqual]: {
    value: BASE_TEXT_FIELD,
  },
  [ConditionTypes.Contains]: {
    value: BASE_TEXT_FIELD,
  },
  [ConditionTypes.DoesNotContain]: {
    value: BASE_TEXT_FIELD,
  },
  [ConditionTypes.IsNull]: {},
  [ConditionTypes.IsNotNull]: {},
  [ConditionTypes.IsGreaterThan]: {
    value: BASE_TEXT_FIELD,
  },
  [ConditionTypes.IsLessThan]: {
    value: BASE_TEXT_FIELD,
  },
  [ConditionTypes.IsBetween]: {
    value1: BASE_TEXT_FIELD,
    value2: BASE_TEXT_FIELD,
  },
  [ConditionTypes.LenGreaterThan]: {
    value: BASE_TEXT_FIELD,
  },
  [ConditionTypes.LenLessThan]: {
    value: BASE_TEXT_FIELD,
  },
};
