import { WizardContext, WizardHandlersContext } from 'modules/digital-threads-mapping-wizard/wizard-context/context';
import { generateEndConnectionId } from 'modules/digital-threads-mapping-wizard/tools';
import { memo, useContext, useMemo, useState } from 'react';
import { Edit } from '@mui/icons-material';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { Tooltip } from '@mui/material';
import { useStyles } from './styles';

export enum Types {
  Start = 'start',
  End = 'end',
}

const ColumnCard = ({ parentId, title, type }) => {
  const [hovered, setHovered] = useState(false);
  const classes = useStyles();
  const { insidePreview } = useContext(PreviewContext);

  const { draggableItem, stepData } = useContext(WizardContext);
  const { updateDraggableItem, createConnection, openEditDefaultValueModal } = useContext(WizardHandlersContext);

  const { connections } = stepData!.config.board;

  const endConnectionId = generateEndConnectionId(parentId, title);

  const shape = useMemo(() => stepData?.config.board.shapes.find((s) => s.id === parentId), [parentId, stepData]);

  const typeProps = {
    ...(type === Types.Start
      ? {
          onMouseDown: (e) => {
            e.stopPropagation();
          },
          draggable: true,
          onDragStart: (e) => {
            e.stopPropagation();

            updateDraggableItem({
              parentId,
              title,
            });
          },
          onDrag: (e) => {
            e.stopPropagation();
          },
          onDragExit: (e) => {
            e.stopPropagation();

            updateDraggableItem({
              parentId: '',
              title: '',
            });

            setHovered(false);
          },
        }
      : {}),
    ...(type === Types.End && !connections.find((c) => c.end === endConnectionId)
      ? {
          onDragOver: (e) => {
            e.stopPropagation();
            e.preventDefault();

            if (draggableItem.parentId !== parentId) {
              setHovered(true);
            }
          },
          onDragLeave: (e) => {
            e.stopPropagation();

            setHovered(false);
          },
          onDrop: () => {
            createConnection(draggableItem, {
              parentId,
              title,
            });

            updateDraggableItem({
              parentId: '',
              title: '',
            });

            setHovered(false);
          },
        }
      : {}),
  };

  const defaultValue = shape?.metadata?.defaultValues?.[title];

  const btnProps = useMemo(
    () =>
      insidePreview
        ? { className: classes.editButtonDisabled }
        : {
            role: 'presentation',
            onClick: () => {
              openEditDefaultValueModal({
                shapeId: parentId,
                col: title,
              });
            },
            className: classes.editButton,
          },
    [insidePreview, classes.editButtonDisabled, classes.editButton, openEditDefaultValueModal, parentId, title],
  );

  return (
    <div style={{ padding: '6px 10px' }}>
      <div
        className={type === Types.Start ? classes.draggable : ''}
        style={{
          height: 20,
          display: 'flex',
          alignItems: 'center',
          borderColor: '#8f8f8f',
          borderRadius: 4,
          boxShadow: hovered ? '0 0px 4px 0 red' : '0 0px 4px 0 #8f8f8f',
          padding: '0 4px',

          justifyContent: 'space-between',
          gap: 10,
        }}
        {...typeProps}
      >
        <span title={title} className={classes.text}>
          {title}
        </span>

        {typeof defaultValue === 'string' && (
          <Tooltip title={defaultValue.length ? `Default value is ${defaultValue}` : 'No default value'}>
            <div {...btnProps}>
              <Edit className={classes.icon} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default memo(ColumnCard);
