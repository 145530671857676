import { Shape } from 'modules/shared/components/DraggableLayout';
import cloneDeep from 'lodash.clonedeep';
import { Step } from 'modules/digital-threads-mapping-wizard/types';
import { OUTPUT, INPUT } from '../../constants';
import { generateEndConnectionId } from '../../tools';

export const handleValidateDefaultValues = (setSteps, selectedStep) => {
  return () => {
    setSteps((cs) => {
      return cs.map((step) => {
        if (step.id === selectedStep) {
          const clone: Step = cloneDeep(step);
          const { shapes, connections } = clone.config.board;

          const inputShapes = shapes.filter((s) => s.type === INPUT);
          const outputShape = shapes.find((s) => s.type === OUTPUT);

          inputShapes.forEach((s) => {
            if (s.metadata.defaultValues) {
              delete s.metadata.defaultValues;
            }
          });

          if (outputShape) {
            const currentKeys = Object.keys(outputShape.metadata.defaultValues || {});

            currentKeys.forEach((k) => {
              if (!connections.find((c) => c.end === generateEndConnectionId(outputShape.id, k))) {
                delete outputShape.metadata.defaultValues[k];
              }
            });
          }

          return clone;
        }

        return step;
      });
    });
  };
};

export const handleUpdateDefaultValue = (setSteps, selectedStep) => {
  return (shapeId: string, col: string, value: string) => {
    setSteps((ps) =>
      ps.map((step) => {
        if (step.id === selectedStep) {
          return {
            ...step,
            config: {
              ...step.config,
              board: {
                ...step.config.board,
                shapes: step.config.board.shapes.map((s) => {
                  if (s.id === shapeId) {
                    const clone: Shape = cloneDeep(s);
                    clone.metadata.defaultValues = clone.metadata.defaultValues || {};
                    clone.metadata.defaultValues[col] = value;
                    return clone;
                  }
                  return s;
                }),
              },
            },
          };
        }
        return step;
      }),
    );
  };
};
