import { Dialog, Title, Content } from 'modules/shared/components/Dialog';
import { memo } from 'react';
import Select from 'modules/shared/components/Select2';
import { noop } from 'modules/shared/utils';
import Config from '../Config';
import { RuleTypesListOptions, ConditionTypesListOptions } from '../../constants';
import { useStyles } from './styles';

const MODAL_ID = 'preview-transformation-modal';

const Modal = ({ isOpen, close, type, config }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={close}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={close}>
        Edit transformation configuration
      </Title>
      <Content className={classes.dialogContent}>
        <Select
          id="category"
          value={[type]}
          options={[...RuleTypesListOptions, ...ConditionTypesListOptions]}
          className={classes.select}
          disableClear
          disabled
          onChange={noop}
          required
        />
        <Config type={type} config={config} disabled setConfig={noop} />
      </Content>
    </Dialog>
  );
};

export default memo(Modal);
