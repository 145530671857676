import { Button, Box, Divider } from '@mui/material';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useEffect, useState } from 'react';
import Select from 'modules/shared/components/Select2';
import { v4 as uuid } from 'uuid';
import { useStyles } from './styles';
import Config from '../Config';
import { ConditionTypes, ConditionTypesListOptions, RuleTypesListOptions } from '../../constants';
import { conditionConfigPerType, ruleConfigPerType } from '../constants';
import { Rule, Condition } from '../../types';

const MODAL_ID = 'create-condition-modal';
const FORM_ID = 'condition-modal';

const CreateConditionModalUi = ({ isOpen, close, onSubmit }) => {
  const classes = useStyles();
  const [conditions, setConditions] = useState<Condition[]>([]);

  useEffect(() => {
    if (isOpen) {
      setConditions([]);
    }
  }, [isOpen]);

  const handleAddCondition = () => {
    setConditions((prev) => [...prev, { id: uuid(), type: '', config: {}, rules: [] }]);
  };

  const handleConditionTypeChange = (index: number, type: ConditionTypes | '') => {
    setConditions((prev) => {
      const newConditions = [...prev];

      if (!type) {
        newConditions.splice(index, 1);
      } else {
        newConditions[index].type = type;
        newConditions[index].config = conditionConfigPerType[type] || {};
      }

      return newConditions;
    });
  };

  const handleAddRule = (conditionIndex: number) => {
    setConditions((prev) => {
      const newConditions = [...prev];
      newConditions[conditionIndex].rules.push({ id: uuid(), type: '', config: {} });
      return newConditions;
    });
  };

  const handleRuleChange = (conditionIndex: number, ruleIndex: number, key: keyof Rule, value: any) => {
    setConditions((prev) => {
      const newConditions = [...prev];
      const condition = newConditions[conditionIndex];
      const rules = [...condition.rules];

      if (!value && key === 'type') {
        rules.splice(ruleIndex, 1);
      } else {
        rules[ruleIndex] = { ...rules[ruleIndex], [key]: value };
        if (key === 'type') {
          rules[ruleIndex].config = ruleConfigPerType[value] || {};
        }
      }

      newConditions[conditionIndex].rules = rules;
      return newConditions;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(conditions);
  };

  return (
    <Dialog classes={{ paper: classes.dialog }} fullWidth onClose={close} aria-labelledby={MODAL_ID} open={isOpen}>
      <Title id={MODAL_ID} onClose={close}>
        Conditions Configuration
      </Title>
      <Content className={classes.dialogContent}>
        <Box component="form" id={FORM_ID} onSubmit={handleSubmit}>
          {conditions.map((condition, conditionIndex) => (
            <div key={condition.id} className={classes.conditionContainer}>
              <h4 className={classes.conditionTitle}>{`Condition ${conditionIndex + 1}`}</h4>
              <Select
                id={`condition-type-${conditionIndex}`}
                value={[condition.type]}
                options={ConditionTypesListOptions}
                labelText="Condition Type"
                className={classes.select}
                onChange={(val) => handleConditionTypeChange(conditionIndex, val[0] || '')}
                required
              />
              <Config
                type={condition.type}
                config={condition.config}
                setConfig={(newConfig) => {
                  const updatedConditions = [...conditions];
                  updatedConditions[conditionIndex].config = newConfig;
                  setConditions(updatedConditions);
                }}
              />
              <div className={classes.rulesContainer}>
                <h5 className={classes.ruleTitle}>Add Rules for the Condition</h5>
                {condition.rules.map((rule, ruleIndex) => (
                  <div key={rule.id} className={classes.ruleItem}>
                    <Select
                      id={`rule-type-${ruleIndex}`}
                      value={[rule.type]}
                      options={RuleTypesListOptions}
                      labelText="Rule Type"
                      onChange={(val) => handleRuleChange(conditionIndex, ruleIndex, 'type', val[0])}
                      required
                    />
                    {rule.type && (
                      <Config
                        type={rule.type}
                        config={rule.config}
                        setConfig={(newConfig) => handleRuleChange(conditionIndex, ruleIndex, 'config', newConfig)}
                      />
                    )}
                  </div>
                ))}
                <Button variant="contained" color="primary" onClick={() => handleAddRule(conditionIndex)}>
                  Add Rule
                </Button>
              </div>
              {conditionIndex !== conditions.length - 1 && <Divider className={classes.divider} />}
            </div>
          ))}
          <Button variant="contained" color="primary" onClick={handleAddCondition}>
            Add Condition
          </Button>
        </Box>
      </Content>
      <Actions>
        <Button variant="contained" color="primary" type="submit" form={FORM_ID}>
          Submit
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(CreateConditionModalUi);
