import { memo, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import Select from 'modules/shared/components/Select2';
import DatePicker from 'modules/shared/components/DatePicker';
import { isValid } from 'date-fns';
import { ruleFieldsConfigs, conditionFieldsConfigs } from '../constants';

interface ConfigProps {
  type: string;
  config: Record<string, any>;
  setConfig: (config: Record<string, any>) => void;
  disabled?: boolean;
}

const Config = ({ type, config = {}, setConfig, disabled = false }: ConfigProps) => {
  const entries = Object.entries(config || {});

  const handlePickerChange = useCallback(
    (date, id) => {
      if (date === null || date === undefined || isValid(date)) {
        const newConfig = { ...config, [id]: date };
        setConfig(newConfig);
      } else {
        const newConfig = { ...config, [id]: null };
        setConfig(newConfig);
      }
    },
    [config, setConfig],
  );

  const fieldsConfig = ruleFieldsConfigs[type] || conditionFieldsConfigs[type];

  if (!entries.length || !fieldsConfig) {
    return null;
  }

  return (
    <>
      {entries.map(([key, value]) => {
        const field = fieldsConfig[key];

        if (!field) {
          return null;
        }

        if (field.type === 'date') {
          return (
            <div key={key} style={{ marginBottom: 16 }}>
              <DatePicker
                formControlProps={{
                  disabled,
                  fullWidth: true,
                }}
                id={key}
                label={key}
                date={value || null}
                changeDate={(val) => handlePickerChange(val, key)}
                midDaySelection
                disabled={disabled}
              />
            </div>
          );
        }

        if (field.isSelect) {
          return (
            <div key={key} style={{ marginBottom: 16 }}>
              <Select
                id={key}
                value={[value]}
                labelText={key}
                disabled={disabled}
                onChange={(val) => {
                  const newConfig = { ...config, [key]: val[0] };
                  setConfig(newConfig);
                }}
                options={field.options}
                required
                disableClear
              />
            </div>
          );
        }

        return (
          <TextField
            key={key}
            id={key}
            label={key}
            value={value || ''}
            fullWidth
            onChange={({ target }) => {
              const { value: newValue } = target;
              const newConfig = { ...config, [key]: field.filterValue(newValue) };
              setConfig(newConfig);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
            type={field.type}
            margin="normal"
            variant="outlined"
          />
        );
      })}
    </>
  );
};

export default memo(Config);
