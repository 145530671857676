import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxHeight: '80vh',
    overflow: 'initial',
    minHeight: 350,
    maxWidth: '50%',
    minWidth: '50%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '70%',
      minWidth: '70%',
    },
  },
  dialogContent: {
    overflowY: 'auto',
    paddingTop: 0,
  },
  select: {
    marginBottom: 20,
  },
  conditionContainer: {
    marginBottom: 30,
  },
  conditionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  rulesContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  ruleTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  ruleItem: {
    marginBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  progress: {
    color: 'rgba(255, 255, 255, 0)',
  },
  divider: {
    margin: '40px 50px !important',
  },
}));
