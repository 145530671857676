import { memo, useContext } from 'react';
import DraggableLayout from 'modules/shared/components/DraggableLayout';
import Menu from 'modules/flow-solution-builder/builder-board-ui/Menu';
import { useBuilderContextMenuState } from 'modules/shared/hooks/base';
import { WizardContext, WizardHandlersContext } from 'modules/digital-threads-mapping-wizard/wizard-context/context';
import BlendConfigModalUi from 'modules/digital-threads-mapping-wizard/blend-config-modal-ui';
import ImportSourceModalUi from 'modules/digital-threads-mapping-wizard/import-source-modal-ui';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { useAdminOrSuperUser } from 'modules/auth/tools';

const Blend = () => {
  const { stepData, layoutRef, connection, projectId } = useContext(WizardContext);
  const { updateShape, deleteConnectionChain } = useContext(WizardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);
  const editable = useAdminOrSuperUser(connection, projectId);

  const { menuConfig, handleMenuClose, openMenu } = useBuilderContextMenuState();

  return (
    <>
      <DraggableLayout
        bgColour="rgb(255, 255, 255)"
        loadingBgColor="rgba(255, 255, 255, 0.2)"
        isLoading={false}
        boardData={stepData!.config.board}
        disableAdaptLayout
        adaptOnMount
        cardDragEnabled={!insidePreview}
        initial={stepData!.config.position}
        layoutRef={layoutRef}
        shapeProps={
          insidePreview || !editable
            ? {}
            : {
                onUpdate: updateShape,
              }
        }
        editableConnectionProps={
          insidePreview || !editable
            ? {}
            : {
                passProps: {
                  computedProps: (id) => {
                    return {
                      style: {
                        cursor: 'context-menu',
                      },
                      onContextMenu: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const { clientX, clientY } = e;
                        const layoutDimensions = layoutRef.current.containerEl!.getBoundingClientRect();

                        openMenu({
                          x: clientY,
                          y: clientX,
                          id,
                          shapeX: clientX - layoutDimensions.x,
                          shapeY: clientY - layoutDimensions.y,
                        });
                      },
                    };
                  },
                },
              }
        }
      />
      <BlendConfigModalUi editable={editable} />

      {!insidePreview && editable && (
        <>
          <ImportSourceModalUi />

          <Menu
            {...menuConfig}
            onClose={handleMenuClose}
            items={[
              {
                title: 'Delete',
                onClick: () => {
                  deleteConnectionChain(menuConfig.meta.id);

                  handleMenuClose();
                },
              },
            ]}
          />
        </>
      )}
    </>
  );
};

export default memo(Blend);
