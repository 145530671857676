import { gql } from '@apollo/client';

export const LOAD_FLOW_SOLUTIONS_BY_PROJECT = gql`
  query LoadFlowSolutionsByProject($projectId: String!, $connection: String!) {
    flowSolutions(project_id: $projectId, connection: $connection) {
      solutionId
      title
      description
      categoryId
      type
      projectId
      board {
        scheduler {
          schedulerLabel
          isActive
          schedulerType
          schedulerId
          schedulerPattern
        }
      }
    }
  }
`;

export const LOAD_FLOW_SOLUTION_BOARD_BY_SOLUTION_ID = gql`
  query LoadFlowSolutionBoardBySolutionId($solutionId: String!, $projectId: String!, $connection: String!) {
    flowSolutionBoardBySolutionId(solution_id: $solutionId, project_id: $projectId, connection: $connection) {
      boardId
      minScale
      maxScale
      canvasSize
      shapes {
        shapeId
        title
        xCoordinate
        yCoordinate
        metadata
        width
        height
        type
      }
      connections {
        connectionId
        start
        end
        startPosition
        endPosition
        color
        type
      }
      flowSolution {
        title
        solutionId
      }
      scheduler {
        schedulerId
        schedulerType
        schedulerLabel
        schedulerPattern
        isActive
      }
    }
  }
`;

export const LOAD_BOARD_ADDITIONAL_CONFIG = gql`
  query LoadBoardAdditionalConfig($boardId: String!, $projectId: String!, $connection: String!) {
    boardAdditionalConfig(board_id: $boardId, project_id: $projectId, connection: $connection) {
      checklistItems {
        dataId
        name
        setUp
        signOff
        fkShapeId
        fkBoardId
      }
    }
  }
`;

export const LOAD_FLOW_SOLUTION_CHECKED_CHECKLIST = gql`
  query LoadFlowSolutionsCheckedCheckistItems($executionId: String!, $connection: String!) {
    flowSolutionsCheckedCheckistItems(execution_id: $executionId, connection: $connection) {
      itemId
      checklistItem {
        dataId
        fkShapeId
      }
      status
      setUp
      signOff
    }
  }
`;

export const LOAD_NOTEBOOK_BY_BOARD_ID = gql`
  query LoadNoteBookByBoardId($connection: String!, $boardId: String!, $projectId: String!) {
    notebook(connection: $connection, board_id: $boardId, project_id: $projectId) {
      content
    }
  }
`;

export const LOAD_LATEST_EXECUTION_DETAILS = gql`
  query getLatestExecutionDetails($boardId: String!, $projectId: String!, $connection: String!) {
    getLatestExecutionDetails(board_id: $boardId, project_id: $projectId, connection: $connection) {
      id
      currentState
      completedAt
      flowStatuses {
        id
        state
        shapeId
        fkFlowId
        completedAt
      }
      shapeStatuses {
        id
        shapeId
        state
        completedAt
      }
    }
  }
`;

export const LOAD_LAST_EXECUTIONS = gql`
  query LoadLastExecutions($connection: String!, $boardId: String!, $projectId: String!) {
    lastExecutions(connection: $connection, board_id: $boardId, project_id: $projectId) {
      id
      createdAt
      startedBy
    }
  }
`;

export const LOAD_EXECUTION_DETAILS_BY_ID = gql`
  query LoadExecutionDetailsById($connection: String!, $boardId: String!, $projectId: String!, $executionId: String!) {
    executionDetailsById(
      connection: $connection
      board_id: $boardId
      project_id: $projectId
      execution_id: $executionId
    ) {
      flowsDetails {
        id
        status
        log {
          id
          startedAt
          completedAt
          status
          issues {
            issueId
            issueInfo
          }
        }
        flow {
          id
          name
        }
        shape {
          name
        }
      }
    }
  }
`;
