import { Connection } from 'modules/shared/components/DraggableLayout';
import cloneDeep from 'lodash.clonedeep';
import { v4 as uuid } from 'uuid';
import { CONDITION_CARD } from '../../constants';
import { Step } from '../../types';

export const handleAddCondition = (setSteps, selectedStep, layoutRef) => {
  return ({ x: shiftX, y: shiftY, connectionId, conditions, conditionType }) => {
    const { x, y, scale } = layoutRef.current;
    const boardMaxScale = 10;
    const shapeWidth = 130;
    const shapeHeight = 50;

    const translateX = (shapeWidth / 2 / boardMaxScale) * scale;
    const translateY = (shapeHeight / 4 / boardMaxScale) * scale;

    const newShapeId = uuid();

    setSteps((s) =>
      s.map((step) => {
        if (step.id === selectedStep) {
          const { shapes, connections } = step.config.board;
          const currentConnection = connections.find((c) => c.connectionId === connectionId)!;

          const updatedStep = {
            ...step,
            config: {
              ...step.config,
              board: {
                ...step.config.board,
                shapes: shapes.concat({
                  id: newShapeId,
                  height: shapeHeight,
                  width: shapeWidth,
                  metadata: { conditions, conditionType },
                  title: 'Condition Group',
                  type: CONDITION_CARD,
                  xCoordinate: (x * -1 + shiftX - translateX) / scale,
                  yCoordinate: (y * -1 + shiftY - translateY) / scale,
                }),
                connections: connections
                  .filter((c) => c.connectionId !== connectionId)
                  .concat({
                    connectionId: uuid(),
                    start: currentConnection.start,
                    end: newShapeId,
                    headSize: 4,
                    tailSize: 4,
                    curveness: 0.5,
                    color: currentConnection?.color,
                    endPosition: 'auto',
                    startPosition: currentConnection.start.includes('start') ? ['left', 'right'] : 'auto',
                    uniqKey: Math.random(),
                  })
                  .concat({
                    connectionId: uuid(),
                    start: newShapeId,
                    end: currentConnection.end,
                    headSize: 4,
                    tailSize: 4,
                    curveness: 0.5,
                    color: currentConnection?.color,
                    endPosition: currentConnection.end.includes('end') ? ['left', 'right'] : 'auto',
                    startPosition: 'auto',
                    uniqKey: Math.random(),
                  }),
              },
            },
          };

          return updatedStep;
        }

        return step;
      }),
    );
  };
};

export const handleUpdateCondition = (setSteps, selectedStep) => {
  return (shapeId, conditions, conditionType) => {
    setSteps((ps) =>
      ps.map((step) => {
        if (step.id === selectedStep) {
          return {
            ...step,
            config: {
              ...step.config,
              board: {
                ...step.config.board,
                shapes: step.config.board.shapes.map((cs) => {
                  if (cs.id === shapeId) {
                    return {
                      ...cs,
                      title: `Condition Group`,
                      metadata: {
                        conditions,
                        conditionType,
                      },
                    };
                  }
                  return cs;
                }),
              },
            },
          };
        }
        return step;
      }),
    );
  };
};

export const handleDeleteCondition = (setSteps, selectedStep) => {
  return (shapeId: string) => {
    setSteps((ps) =>
      ps.map((step) => {
        if (step.id === selectedStep) {
          const clone: Step = cloneDeep(step);

          clone.config.board.shapes = clone.config.board.shapes.filter((s) => s.id !== shapeId);

          let start = '';
          let startPosition: string | string[] = '';
          let end = '';
          let endPosition: string | string[] = '';
          let color = '';

          clone.config.board.connections = clone.config.board.connections
            .reduce((acc, cur) => {
              if (cur.start === shapeId) {
                end = cur.end;
                endPosition = cur.endPosition;
                return acc;
              }

              if (cur.end === shapeId) {
                start = cur.start;
                startPosition = cur.startPosition;
                color = cur.color;

                return acc;
              }

              return acc.concat(cur);
            }, [] as Connection[])
            .concat({
              connectionId: uuid(),
              start,
              end,
              headSize: 4,
              tailSize: 4,
              curveness: 0.5,
              color,
              endPosition,
              startPosition,
              uniqKey: Math.random(),
            });

          return clone;
        }

        return step;
      }),
    );
  };
};
