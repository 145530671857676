import { Button, Box, Grid, Checkbox, FormControlLabel } from '@mui/material';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useContext, useEffect, useState } from 'react';
import Input from 'modules/shared/components/Input';
import { WizardContext, WizardHandlersContext } from 'modules/digital-threads-mapping-wizard/wizard-context/context';
import { useStyles } from './styles';

const MODAL_ID = 'add-column-default-value-modal';

const FORM_ID = 'add-column-default-value-modal-form';

const Modal = () => {
  const classes = useStyles();

  const { isAddColumnTypeModalOpen, currentAddColumn, stepData } = useContext(WizardContext);
  const { closeAddColumnTypeModal, updateStepConfig } = useContext(WizardHandlersContext);

  const [value, setValue] = useState('');
  const [isUuid, setUuid] = useState(false);
  const [isDate, setDate] = useState(false);

  useEffect(() => {
    if (isAddColumnTypeModalOpen) {
      const col = stepData?.config.addColumns?.find((c) => c.colName === currentAddColumn);

      setValue(col?.colType || '');
      setUuid(col?.isUuid || false);
      setDate(col?.isDate || false);
    }
  }, [currentAddColumn, isAddColumnTypeModalOpen, stepData?.config.addColumns]);

  const handleSubmit = (e) => {
    e.preventDefault();

    updateStepConfig({
      addColumns:
        stepData?.config.addColumns?.map((c) => {
          if (c.colName === currentAddColumn) {
            return {
              ...c,
              colType: value,
              isUuid,
              isDate,
            };
          }

          return c;
        }) || [],
    });

    closeAddColumnTypeModal();
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={closeAddColumnTypeModal}
      aria-labelledby={MODAL_ID}
      open={isAddColumnTypeModalOpen}
    >
      <Title id={MODAL_ID} onClose={closeAddColumnTypeModal}>
        Add column default value for "{currentAddColumn}"
      </Title>
      <Content className={classes.dialogContent}>
        <Box component="form" id={FORM_ID} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                removeMargin
                labelText="Value"
                id="value"
                labelProps={{
                  shrink: true,
                }}
                formControlProps={{
                  fullWidth: true,
                  disabled: isUuid || isDate,
                }}
                inputProps={{
                  value,
                  onChange: (e) => setValue(e.target.value),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isUuid}
                    onClick={() => {
                      const next = !isUuid;

                      setUuid(next);
                      if (next) {
                        setDate(false);
                        setValue('');
                      }
                    }}
                  />
                }
                label="Use Uuid"
              />
              <div />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDate}
                    onClick={() => {
                      const next = !isDate;

                      setDate(next);
                      if (next) {
                        setUuid(false);
                        setValue('');
                      }
                    }}
                  />
                }
                label="Use Date"
              />
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Box>
      </Content>
      <Actions>
        <Button variant="contained" color="primary" type="submit" form={FORM_ID} disabled={false}>
          Set
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(Modal);
