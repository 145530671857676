import { ChecklistItemResponse, FlowSolutionExecution } from 'modules/project-flow-solution/types';
import { Shape } from 'modules/shared/components/DraggableLayout';
import { createContext } from 'react';
import { FlowSolutionConnectionType, FlowSolutionState } from '../enums';

export const StepExecuteContext = createContext<{
  currentState: FlowSolutionState;
  shapeSelection: string;
  setShapeSelection?: (flow: string) => void;
  updateStatusOfChecklistCard?: (id: string, status: FlowSolutionConnectionType) => void;
  boardId: string;
  shapes: Shape[];
  checklistItems: Record<string, ChecklistItemResponse[]>;
  playBoard: (flowId) => void;
  executionDetails: FlowSolutionExecution;
}>({
  currentState: FlowSolutionState.EMPTY,
  shapeSelection: '',
  boardId: '',
  shapes: [],
  checklistItems: {},
  playBoard: () => {},
  executionDetails: {} as FlowSolutionExecution,
});
