import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useContext, useEffect, useState, useMemo } from 'react';
import { Button, Box, Divider } from '@mui/material';
import Select from 'modules/shared/components/Select2';
import { v4 as uuid } from 'uuid';
import { useStyles } from './styles';
import Config from '../Config';
import { ConditionTypes, ConditionTypesListOptions, RuleTypesListOptions } from '../../constants';
import { conditionConfigPerType, ruleConfigPerType } from '../constants';
import { WizardContext, WizardHandlersContext } from '../../wizard-context/context';
import { Rule, Condition } from '../../types';

const UpdateConditionModalUi = () => {
  const classes = useStyles();
  const { isUpdateConditionModalOpen: isOpen, editableCondition, stepData } = useContext(WizardContext);
  const { closeUpdateConditionModal, updateCondition } = useContext(WizardHandlersContext);

  const [conditions, setConditions] = useState<Condition[]>([]);
  const [conditionType, setConditionType] = useState<'if' | 'ifElse'>('if');

  const { shapes } = stepData!.config.board;
  const shape = useMemo(() => shapes?.find((s) => s.id === editableCondition), [shapes, editableCondition]);
  useEffect(() => {
    if (isOpen && shape) {
      setConditions(shape.metadata.conditions || []);
      setConditionType(shape.metadata.conditionType || 'if');
    }
  }, [isOpen, shape]);

  const handleAddCondition = () => {
    setConditions((prev) => [...prev, { id: uuid(), type: '', config: {}, rules: [] }]);
  };

  const handleConditionTypeChange = (index: number, type: ConditionTypes | '') => {
    setConditions((prev) => {
      const newConditions = [...prev];

      if (!type) {
        newConditions.splice(index, 1);
      } else {
        newConditions[index].type = type;
        newConditions[index].config = conditionConfigPerType[type] || {};
      }

      return newConditions;
    });
  };

  const handleAddRule = (conditionIndex: number) => {
    setConditions((prev) => {
      const newConditions = [...prev];
      newConditions[conditionIndex].rules.push({ id: uuid(), type: '', config: {} });
      return newConditions;
    });
  };

  const handleRuleChange = (conditionIndex: number, ruleIndex: number, key: keyof Rule, value: any) => {
    setConditions((prev) => {
      const newConditions = [...prev];
      const condition = newConditions[conditionIndex];
      const rules = [...condition.rules];

      if (!value && key === 'type') {
        rules.splice(ruleIndex, 1);
      } else {
        rules[ruleIndex] = { ...rules[ruleIndex], [key]: value };
        if (key === 'type') {
          rules[ruleIndex].config = ruleConfigPerType[value] || {};
        }
      }

      newConditions[conditionIndex].rules = rules;
      return newConditions;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (shape) {
      updateCondition(editableCondition, conditions, conditionType);
      closeUpdateConditionModal();
    }
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={closeUpdateConditionModal}
      aria-labelledby="update-condition-modal"
      open={isOpen}
    >
      <Title id="update-condition-modal" onClose={closeUpdateConditionModal}>
        Edit Condition Configuration
      </Title>
      <Content className={classes.dialogContent}>
        <Box component="form" id="update-condition-modal-form" onSubmit={handleSubmit}>
          {conditions.map((condition, conditionIndex) => (
            <div key={condition.id} className={classes.conditionContainer}>
              <h4 className={classes.conditionTitle}>{`Condition ${conditionIndex + 1}`}</h4>
              <Select
                id={`condition-type-${condition.id}`}
                value={[condition.type]}
                options={ConditionTypesListOptions}
                className={classes.select}
                onChange={(val) => handleConditionTypeChange(conditionIndex, val[0] || '')}
                required
              />
              <Config
                type={condition.type}
                config={condition.config}
                setConfig={(newConfig) => {
                  const updatedConditions = [...conditions];
                  updatedConditions[conditionIndex].config = newConfig;
                  setConditions(updatedConditions);
                }}
              />
              <div className={classes.rulesContainer}>
                <h4 className={classes.ruleTitle}>Edit Rules for Condition</h4>
                {condition.rules.map((rule, ruleIndex) => (
                  <div key={rule.id} className={classes.ruleItem}>
                    <Select
                      id={`rule-type-${rule.id}`}
                      value={[rule.type]}
                      options={RuleTypesListOptions}
                      onChange={(val) => handleRuleChange(conditionIndex, ruleIndex, 'type', val[0] || '')}
                      required
                    />
                    {rule.type && (
                      <Config
                        type={rule.type}
                        config={rule.config}
                        setConfig={(newConfig) => handleRuleChange(conditionIndex, ruleIndex, 'config', newConfig)}
                      />
                    )}
                  </div>
                ))}
                <Button variant="contained" color="primary" onClick={() => handleAddRule(conditionIndex)}>
                  Add Rule
                </Button>
              </div>
              {conditionIndex !== conditions.length - 1 && <Divider className={classes.divider} />}
            </div>
          ))}
          <Button variant="contained" color="primary" onClick={handleAddCondition}>
            Add Condition
          </Button>
        </Box>
      </Content>
      <Actions>
        <Button variant="contained" color="primary" type="submit" form="update-condition-modal-form">
          Save
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(UpdateConditionModalUi);
