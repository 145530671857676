import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Board, Connection, Shape } from 'modules/shared/components/DraggableLayout';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { LOAD_FLOW_SOLUTION_BOARD_BY_SOLUTION_ID } from '../gql';
import { FlowSolutionBoard, FlowSolutionShape, FlowSolutionConnector } from '../types';

const BASE_CONNECTION = {
  curveness: 0.7,
  headSize: 4,
  tailSize: 4,
};

const parseCard = (cards: FlowSolutionShape[]): Shape[] => {
  return cards.map((card) => {
    return {
      id: card.shapeId,
      xCoordinate: card.xCoordinate,
      yCoordinate: card.yCoordinate,
      metadata: JSON.parse(card.metadata),
      title: card.title,
      width: card.width,
      height: card.height,
      type: card.type,
    };
  });
};

const parseConnector = (connections: FlowSolutionConnector[]): Connection[] => {
  return connections.map((connection) => {
    return {
      connectionId: connection.connectionId,
      start: connection.start,
      end: connection.end,
      startPosition: connection.startPosition,
      endPosition: connection.endPosition,
      color: connection.color,
      type: connection.type,
      ...BASE_CONNECTION,
      uniqKey: Math.random(),
    };
  });
};

const parseBoard = (board: FlowSolutionBoard): Board | undefined => {
  if (board) {
    return {
      boardId: board.boardId,
      maxScale: board.maxScale,
      minScale: board.minScale,
      canvasSize: board.canvasSize,
      shapes: parseCard(board.shapes),
      connections: parseConnector(board.connections),
      flowSolution: board.flowSolution ?? undefined,
      scheduler: board.scheduler,
    };
  }

  return undefined;
};

export const useFlowSolutionBoardBySolutionId = (
  solutionId: string,
  projectId: string,
  connection: string,
  skip: boolean,
  onError: () => void,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const { data, loading, refetch, error } = useQuery(LOAD_FLOW_SOLUTION_BOARD_BY_SOLUTION_ID, {
    variables: {
      solutionId,
      connection,
      projectId,
    },
    skip: !connection || !solutionId || skip,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'network-only',
  });

  const board = useMemo(
    () => parseBoard(data?.flowSolutionBoardBySolutionId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [solutionId, data?.flowSolutionBoardBySolutionId],
  );

  const refetchBoard = useCallback(() => {
    setIsLoadingUpdate(true);
    return refetch()
      .catch((_error) => {
        enqueueSnackbar(_error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        setIsLoadingUpdate(false);
      })
      .finally(() => {
        setIsLoadingUpdate(false);
      });
  }, [refetch, enqueueSnackbar]);

  useEffect(() => {
    if (error && !loading) {
      onError();
    }
  }, [error, loading, onError]);

  return {
    board,
    loading,
    isLoadingUpdate,
    refetch: refetchBoard,
  };
};
