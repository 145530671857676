import { Dialog, Title, Content } from 'modules/shared/components/Dialog';
import { memo } from 'react';
import Select from 'modules/shared/components/Select2';
import { noop } from 'modules/shared/utils';
import Config from '../Config';
import { ConditionTypesListOptions } from '../../constants';
import { useStyles } from './styles';

const MODAL_ID = 'preview-condition-modal';

const PreviewConditionModalUi = ({ isOpen, close, conditions }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={close}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={close}>
        Preview Condition Configuration
      </Title>
      <Content className={classes.dialogContent}>
        {conditions.map((condition, conditionIndex) => (
          <div key={condition.id} style={{ marginBottom: '30px' }}>
            <h4>{`Condition ${conditionIndex + 1}`}</h4>
            <Select
              id={`condition-type-${condition.id}`}
              value={[condition.type]}
              options={ConditionTypesListOptions}
              className={classes.select}
              disableClear
              disabled
              onChange={noop}
              required
            />
            <Config type={condition.type} config={condition.config} disabled setConfig={noop} />

            <div style={{ marginTop: '20px' }}>
              <h4>Associated Rules for Condition {conditionIndex + 1}</h4>
              {condition.rules.map((rule) => (
                <div key={rule.id} style={{ marginBottom: '16px' }}>
                  <h5>{`Rule: ${rule.type}`}</h5>
                  <Config type={rule.type} config={rule.config} disabled setConfig={noop} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </Content>
    </Dialog>
  );
};

export default memo(PreviewConditionModalUi);
