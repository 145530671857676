import { memo, useCallback, useContext } from 'react';
import { CloudUpload, ContentCopy, DataObject, Dashboard } from '@mui/icons-material';
import { IconButton, CircularProgress, Chip } from '@mui/material';

import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import classNames from 'classnames';
import { useAdminOrSuperUser } from 'modules/auth/tools';
import { useToggle } from 'modules/shared/hooks/base';
import { useStyles } from './styles';
import Provider from '../wizard-context';
import Stepper from '../stepper-ui';
import Builder from './Builder';
import { WizardContext, WizardHandlersContext } from '../wizard-context/context';
import CopyModalUi from '../copy-modal-ui';
import { Views } from '../constants';
import { JsonDisplay } from './JsonPreview';

const WizardUi = () => {
  const classes = useStyles();
  const { connection, projectId } = useProjectInfoFromSearch();
  const { baseInfoLoading, templateId, isDraft, steps, selectedStep, view, jsonConfigs, publishing } =
    useContext(WizardContext);
  const { resetState, changeView, publish } = useContext(WizardHandlersContext);

  const editable = useAdminOrSuperUser(connection, projectId);

  const [isCopyModalOpen, { activate: openCopyModal, deactivate: closeCopyModal }] = useToggle();

  const handlePublish = useCallback(() => {
    publish(steps, selectedStep, templateId);
  }, [publish, steps, selectedStep, templateId]);

  if (baseInfoLoading || !templateId) {
    return (
      <div className={classNames(classes.container, classes.center)}>
        <CircularProgress size={50} />
      </div>
    );
  }

  if (view === Views.BOARD) {
    return (
      <div className={classes.container}>
        <div className={classes.stepper}>{editable && <Stepper />}</div>
        <div className={classes.content}>
          <div className={classes.actionsWrapper}>
            <div />
            <div className={classes.buttons}>
              {isDraft && !publishing && (
                <div className={classes.rootChipDraft} role="presentation" onClick={resetState}>
                  <Chip className={`${classes.chipDraft} draft`} label="Rough Draft" />
                  <Chip className={`${classes.chipDraft} remove`} label="Reset" />
                </div>
              )}

              <IconButton onClick={openCopyModal} title="Copy" disabled={publishing || baseInfoLoading}>
                {baseInfoLoading ? (
                  <div className={classes.spinnerWrap}>
                    <CircularProgress size={20} />
                  </div>
                ) : (
                  <ContentCopy />
                )}
              </IconButton>
              {editable && (
                <IconButton onClick={handlePublish} title="Publish" disabled={!isDraft}>
                  {publishing ? (
                    <div className={classes.spinnerWrap}>
                      <CircularProgress size={20} />
                    </div>
                  ) : (
                    <CloudUpload />
                  )}
                </IconButton>
              )}
              <IconButton
                onClick={() => {
                  changeView(Views.JSON);
                }}
                title="Change to JSON view"
                disabled={publishing || baseInfoLoading}
              >
                {baseInfoLoading ? (
                  <div className={classes.spinnerWrap}>
                    <CircularProgress size={20} />
                  </div>
                ) : (
                  <DataObject />
                )}
              </IconButton>
            </div>
          </div>
          <Builder />
          <CopyModalUi isOpen={isCopyModalOpen} close={closeCopyModal} />
        </div>
      </div>
    );
  }

  if (view === Views.JSON) {
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.actionsWrapper}>
            <div>Actual published version of mappings</div>
            <div className={classes.buttons}>
              <IconButton
                onClick={() => {
                  changeView(Views.BOARD);
                }}
                title="Change to Board view"
                disabled={publishing || baseInfoLoading}
              >
                {baseInfoLoading ? (
                  <div className={classes.spinnerWrap}>
                    <CircularProgress size={20} />
                  </div>
                ) : (
                  <Dashboard />
                )}
              </IconButton>
            </div>
          </div>

          <div className={classes.jsonsLayout}>
            <div className={classes.jsonsContainer}>
              <div className={classes.jsonWrapper}>
                <JsonDisplay data={jsonConfigs.config} />
              </div>
              {jsonConfigs.config ? (
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(jsonConfigs.config!);
                  }}
                  size="small"
                  style={{ position: 'absolute', top: 5, right: 5 }}
                  title="Copy to clipboard"
                >
                  <ContentCopy />
                </IconButton>
              ) : null}
            </div>

            <div className={classes.jsonsContainer}>
              <div className={classes.jsonWrapper}>
                <JsonDisplay data={jsonConfigs.mappings} />
              </div>
              {jsonConfigs.mappings ? (
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(jsonConfigs.mappings!);
                  }}
                  size="small"
                  style={{ position: 'absolute', top: 5, right: 5 }}
                  title="Copy to clipboard"
                >
                  <ContentCopy />
                </IconButton>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const WithProvider = (props) => {
  const { connection, projectId } = useProjectInfoFromSearch();

  const { templateId } = props;

  return (
    <Provider {...props} key={`${connection}-${projectId}-${templateId}`}>
      <WizardUi {...props} key={`${connection}-${projectId}-${templateId}`} />
    </Provider>
  );
};

export default memo(WithProvider);
