import { v4 as uuid } from 'uuid';
import { StepConfig } from 'modules/digital-threads-mapping-wizard/types';
import { StepType, DEFAULT_CANVAS_SIZE, INITIAL_BOARD_CONFIG, MAX_SCALE, MIN_SCALE } from '../../constants';
import {
  getAdditionalConfigByType,
  getBaseFieldsBasedOnSteps,
  getDefaultShapesByType,
  normalizeSteps,
} from '../../tools';

export const handleCreateStep = (setSteps, handleSetSelectedStep, baseFields, templateId) => {
  return (type: StepType) => {
    const newId = uuid();

    handleSetSelectedStep(newId);
    setSteps((s) => {
      const fields = getBaseFieldsBasedOnSteps(baseFields, s, s.length);

      return s.concat({
        id: newId,
        type,
        config: {
          board: {
            boardId: uuid(),
            minScale: MIN_SCALE,
            maxScale: MAX_SCALE,
            canvasSize: DEFAULT_CANVAS_SIZE,
            shapes: getDefaultShapesByType(type, templateId, fields),
            connections: [],
          },
          position: INITIAL_BOARD_CONFIG,
          ...getAdditionalConfigByType(type),
        },
      });
    });
  };
};

export const handleDeleteStep = (setSteps, selectedStep, handleSetSelectedStep, baseFields) => {
  return (id: string) => {
    setSteps((prevSteps) => {
      const newSteps = prevSteps.filter((step) => step.id !== id);

      if (selectedStep === id) {
        const newSelectedStepId = newSteps.length ? newSteps[0].id : '';
        handleSetSelectedStep(newSelectedStepId);
      }

      return normalizeSteps(newSteps, baseFields);
    });
  };
};

export const handleSelectStep = (setSteps, handleSetSelectedStep, baseFields) => {
  return (stepId: string) => {
    handleSetSelectedStep(stepId);
    setSteps((steps) => normalizeSteps(steps, baseFields));
  };
};

export const handleUpdateStepConfig = (setSteps, selectedStep) => {
  return (stepConfig: Partial<StepConfig>) => {
    setSteps((ps) =>
      ps.map((s) => {
        if (s.id === selectedStep) {
          return {
            ...s,
            config: {
              ...s.config,
              ...stepConfig,
            },
          };
        }
        return s;
      }),
    );
  };
};
