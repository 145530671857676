import { Card, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { memo, useCallback, useContext, useRef, useState } from 'react';
import { WizardHandlersContext } from 'modules/digital-threads-mapping-wizard/wizard-context/context';

import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { DraggableShapeProps } from '../../types';
import { useStyles } from './styles';

const CombineColumnCard = ({ width, height, title, id }: DraggableShapeProps) => {
  const classes = useStyles();

  const { deleteCombineColumn, openUpdateCombineModalModal } = useContext(WizardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const menuItem = insidePreview
    ? []
    : [
        <MenuItem
          key="edit"
          onClick={() => {
            openUpdateCombineModalModal(id);
            handleMenuClose();
          }}
        >
          Edit
        </MenuItem>,
        <MenuItem
          key="delete"
          onClick={() => {
            deleteCombineColumn(id);
            handleMenuClose();
          }}
        >
          Delete
        </MenuItem>,
      ];

  return (
    <Card className={classes.container} style={{ width, height }}>
      <div title={title} className={`${classes.headerItem} ${classes.headerItemText}`}>
        {title}
      </div>
      {!insidePreview && (
        <div className={classes.headerItemMenuContainer}>
          <div ref={anchorRef} />
          <IconButton disableRipple className={classes.headerItemMenu} size="small" onClick={handleMenuOpen}>
            <MoreVertIcon className={`${classes.headerItem} ${classes.headerItemMenu}`} />
          </IconButton>
        </div>
      )}

      <Menu anchorEl={anchorRef.current} open={isMenuOpen} onClose={handleMenuClose}>
        {menuItem}
      </Menu>
    </Card>
  );
};

export default memo(CombineColumnCard);
