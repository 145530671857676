import React, { memo, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Menu, MenuItem } from '@material-ui/core';

import { StepExecuteContext } from 'modules/project-flow-solution/board-ui/contexts/StepExecuteContext';
import { useToggle } from 'modules/shared/hooks/base';

import {
  FlowSolutionConnectionType,
  FlowSolutionState,
  ShapeExecutionState,
} from 'modules/project-flow-solution/board-ui/enums';
import { useUpdateFlowSolutionChecklistShape } from 'modules/project-flow-solution/hooks';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { useSearch } from 'modules/shared/hooks/url';
import { useAdminOrSuperUser } from 'modules/auth/tools';
import useHookStyles from '../PocCard/hooks/useHookStyles';
import EditModal from './EditModal';
import { DraggableShapeProps } from '../types';
import CardUi from './CardUi';
import ChecklistModal from './ChecklistModal';
import { LayoutContext } from '../../DraggableLayout/context/LayoutContext';

const ChecklistShape = ({ id, title, scale, metadata = {}, boardId, onUpdate, onDelete }: DraggableShapeProps) => {
  const { projectId, connection } = useProjectInfoFromSearch();
  const [isMenuOpen, { activate, deactivate }] = useToggle(false);

  const [isEditModalOpen, { activate: openEditModal, deactivate: closeEditModal }] = useToggle(false);
  const [isChecklistModalOpen, { activate: openChecklistModal, deactivate: closeChecklistModal }] = useToggle(false);

  const anchorRef = useRef(null);
  const { set } = useSearch();

  const {
    isBuilder,
    handlers: { updateChecklistItems },
  } = useContext(LayoutContext);

  const { shapeSelection, executionDetails, setShapeSelection, updateStatusOfChecklistCard } =
    useContext(StepExecuteContext);

  const styleCardUI = useHookStyles(shapeSelection, id, executionDetails);

  const { dateActual, datePlan } = metadata || {};

  useEffect(() => {
    set({ modalId: isEditModalOpen ? id : '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditModalOpen]);
  const handleClick = () => {
    setShapeSelection?.(shapeSelection === id ? '' : id);
  };

  const handleDelete = useCallback(() => {
    onDelete?.(id);
    updateChecklistItems(id, undefined);
  }, [id, onDelete, updateChecklistItems]);

  const handleBuilderUpdate = useCallback(
    (close, data, { checklistItems }) => {
      const { shapeId, ...rest } = data;

      onUpdate?.(shapeId, rest);
      updateChecklistItems(shapeId, checklistItems);

      close();
    },
    [onUpdate, updateChecklistItems],
  );

  const shapeExecutionDetails = executionDetails?.shapeStatuses?.find((it) => it.shapeId === id);

  const readOnlyCardActions = ![
    FlowSolutionState.COMPLETED,
    FlowSolutionState.EMPTY,
    FlowSolutionState.ERROR,
    FlowSolutionState.STOPPED,
  ].includes(executionDetails.currentState);

  const executeFlow = (status: FlowSolutionConnectionType) => {
    updateStatusOfChecklistCard?.(id, status);
  };

  const actionsDisabled = isBuilder || shapeExecutionDetails?.state !== ShapeExecutionState.PROCESSING;

  const { loading, update } = useUpdateFlowSolutionChecklistShape(boardId, projectId, connection);

  const editable = useAdminOrSuperUser(connection, projectId);

  const menuItems: React.ReactNode[] = [
    ...(readOnlyCardActions && editable // && isFlowExecuting(threads, id)
      ? [
          <MenuItem
            key="update-checklist-card-item"
            onClick={() => {
              openChecklistModal();
              deactivate();
            }}
          >
            Update Checklist
          </MenuItem>,
        ]
      : []),

    ...((isBuilder || editable) && !readOnlyCardActions
      ? [
          <MenuItem
            key="edit-checklist-card-item"
            onClick={() => {
              openEditModal();
              deactivate();
            }}
          >
            Edit
          </MenuItem>,
        ]
      : []),

    ...(isBuilder
      ? [
          <MenuItem key="delete-checklist-card-item" onClick={handleDelete}>
            Delete
          </MenuItem>,
        ]
      : []),
  ];

  const actualDate = useMemo(() => {
    if (!executionDetails.currentState) {
      return dateActual;
    }
    return executionDetails.shapeStatuses?.find((s) => s.shapeId === id)?.completedAt;
  }, [dateActual, executionDetails, id]);

  return (
    <>
      <CardUi
        id={id}
        scale={scale}
        title={title}
        dateActual={actualDate}
        datePlan={datePlan}
        onMenuClick={activate}
        actionsDisabled={actionsDisabled}
        menu={
          menuItems.length ? (
            <>
              <div ref={anchorRef} />
              <Menu anchorEl={anchorRef.current} open={isMenuOpen} onClose={deactivate}>
                {menuItems}
              </Menu>
            </>
          ) : (
            <></>
          )
        }
        containerStyle={styleCardUI}
        onClick={handleClick}
        onSuccess={() => {
          if (!isBuilder) {
            executeFlow(FlowSolutionConnectionType.SUCCESS);
          }
        }}
        onFail={() => {
          if (!isBuilder) {
            executeFlow(FlowSolutionConnectionType.FAIL);
          }
        }}
        hasActionable
      />
      <EditModal
        key={`edit-${isEditModalOpen}`}
        open={isEditModalOpen}
        title={title}
        shapeId={id}
        boardId={boardId}
        datePlan={datePlan}
        dateActual={dateActual}
        cardSaving={loading}
        onUpdateCard={isBuilder ? handleBuilderUpdate : update}
        onClose={closeEditModal}
      />
      {!isBuilder && (
        <ChecklistModal
          key={`checklist-${isChecklistModalOpen}`}
          open={isChecklistModalOpen}
          shapeId={id}
          onClose={closeChecklistModal}
        />
      )}
    </>
  );
};

export default memo(ChecklistShape);
