import { FormControl, FormControlLabel, InputLabel, Radio, RadioGroup as RadioGroupMUI } from '@material-ui/core';
import { FC } from 'react';
import { useStyles } from './styles';

type Option = {
  label: string;
  value: any;
};

type RadioGroupProps = {
  label?: string;
  value: any;
  onChange: (value: any) => void;
  items: Option[];
  className?: string;
  readOnly?: boolean;
};
const RadioGroup: FC<RadioGroupProps> = ({ items, onChange, value, label, className, readOnly }) => {
  const classes = useStyles();

  const handleOnChange = (_, _value) => {
    onChange(_value);
  };
  return (
    <FormControl fullWidth className={className}>
      {Boolean(label) && (
        <InputLabel shrink className={classes.label}>
          {label}
        </InputLabel>
      )}
      <RadioGroupMUI
        style={{ marginTop: 14 }}
        row
        aria-label="position"
        name="position"
        value={value}
        onChange={handleOnChange}
      >
        {items.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio color="primary" />}
            label={item.label}
            disabled={readOnly}
          />
        ))}
      </RadioGroupMUI>
    </FormControl>
  );
};
export default RadioGroup;
