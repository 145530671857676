import { Connection, Shape } from 'modules/shared/components/DraggableLayout';
import { endConnectionDelimeter, OUTPUT, startConnectionDelimeter, TRANSFORMATION, CONDITION_CARD } from '../constants';
import { ConditionMapping, ParsedConnection } from '../types';

const processConditions = (shape: Shape): ConditionMapping[] => {
  return shape.metadata.conditions.map((condition) => {
    const conditionRules = condition.rules.map((rule) => ({
      [rule.type]: rule.config,
    }));

    const conditionConfig = condition.config?.value ? { value: condition.config.value } : {};

    return {
      [condition.type]: {
        ...conditionConfig,
        rules: conditionRules.length ? conditionRules : [],
      },
    };
  });
};

const recursiveParse = (
  c: Connection,
  d: ParsedConnection,
  shapes: Shape[],
  connections: Connection[],
): ParsedConnection => {
  const [idToSearch, endConnectionColumn] = c.end.split(endConnectionDelimeter);
  const endShape = shapes.find((s) => s.id === idToSearch);

  if (!endShape) {
    d.source = '';
    return d;
  }

  if (endShape.type === TRANSFORMATION) {
    const endConnection = connections.find((c1) => c1.start === endShape.id)!;
    d.rules!.push({
      [endShape.metadata.type]: endShape.metadata.config,
    });
    return recursiveParse(endConnection, d, shapes, connections);
  }

  if (endShape.type === CONDITION_CARD) {
    const endConnection = connections.find((c1) => c1.start === endShape.id)!;
    const conditionGroup = processConditions(endShape);
    if (endShape.metadata.conditionType === 'if') {
      d.if = conditionGroup;
    } else if (endShape.metadata.conditionType.toLowerCase() === 'ifelse') {
      d.ifelse = conditionGroup;
    }
    return recursiveParse(endConnection, d, shapes, connections);
  }

  if (endShape.type === OUTPUT) {
    d.target = endConnectionColumn;
    return d;
  }

  d.source = '';
  return d;
};

const processInputShapeColumn = (
  shapeId: string,
  column: string,
  shapes: Shape[],
  connections: Connection[],
): ParsedConnection[] => {
  const startId = `${shapeId}${startConnectionDelimeter}${column}`;
  const startConnections = connections.filter((c) => c.start === startId);

  if (!startConnections.length) {
    return [];
  }

  return startConnections.reduce((acc, startConnection) => {
    const data: ParsedConnection = {
      source: column,
      target: '',
      rules: [],
      if: [],
      ifelse: [],
      defaultValue: '',
    };

    const result = recursiveParse(startConnection, data, shapes, connections);

    if (result.source && result.target) {
      const { source, target, rules, if: conditionsIf, ifelse: conditionsIfElse } = result;

      const parsedResult: ParsedConnection = {
        source,
        target,
        defaultValue: '',
        ...(rules?.length ? { rules } : {}),
        ...(conditionsIf && conditionsIf.length ? { if: conditionsIf } : {}),
        ...(conditionsIfElse && conditionsIfElse.length ? { ifelse: conditionsIfElse } : {}),
      };

      return acc.concat(parsedResult);
    }

    return acc;
  }, [] as ParsedConnection[]);
};

export const processInputShape = (shape: Shape, allShapes: Shape[], connections: Connection[]) => {
  const {
    metadata: { columns = [], category, template, item },
    id,
  } = shape;

  if (!columns) {
    throw new Error('Unable to process step. There is no imported source');
  }

  const outputShape: Shape = allShapes.find((s) => s.type === OUTPUT)!;

  const results = columns.reduce((acc, column) => {
    const parsed = processInputShapeColumn(id, column, allShapes, connections);

    if (parsed.length) {
      parsed.forEach((p) => {
        p.defaultValue = outputShape.metadata.defaultValues?.[p.target] ?? '';
      });
    }

    return acc.concat(parsed);
  }, [] as ParsedConnection[]);

  const mappings = results.map((r) => r);

  return {
    config: {
      category,
      template,
      item,
    },
    mappings,
  };
};
