import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash.clonedeep';
import { ConnectionPoint } from '../../types';
import { StepType } from '../../constants';
import { deleteConnectionChainFromStep, generateEndConnectionId, generateStartConnectionId } from '../../tools';

const generateHex = () => {
  const rand = Math.random().toString(16).substring(2, 8);

  return `#${rand}`;
};

export const handleDeleteConnectionChain = (setSteps, selectedStep, validateDefaultValues) => {
  return (connectionId: string) => {
    setSteps((prevSteps) =>
      prevSteps.map((step) => {
        if (step.id === selectedStep) {
          return deleteConnectionChainFromStep(cloneDeep(step), connectionId);
        }
        return step;
      }),
    );
    validateDefaultValues();
  };
};

export const handleCreateConnection = (
  setSteps,
  selectedStep,
  stepData,
  layoutRef,
  addCombineColumnHandler,
  updateDefaultValueHandler,
) => {
  return (start: ConnectionPoint, end: ConnectionPoint) => {
    const connectionId = uuid();

    if ([StepType.TRANSFORM, StepType.BLEND].includes(stepData!.type)) {
      updateDefaultValueHandler(end.parentId, end.title, '');
    }

    if (stepData!.type === StepType.COMBINE) {
      const startId = generateStartConnectionId(start.parentId, start.title);
      const endId = generateEndConnectionId(end.parentId, end.title);
      const startElPos = document.getElementById(startId)!.getBoundingClientRect();
      const endElPos = document.getElementById(endId)!.getBoundingClientRect();

      const leftX = startElPos.x + startElPos.width;
      const leftY = startElPos.y + startElPos.height / 5;
      const rightX = endElPos.x;
      const rightY = endElPos.y + endElPos.height / 5;

      const midX = (leftX + rightX) / 2;
      const midY = (leftY + rightY) / 2;
      const container = layoutRef.current.containerEl!.getBoundingClientRect();

      const shapeId = uuid();

      addCombineColumnHandler({
        x: midX - container.x,
        y: midY - container.y,
        title: '',
        start: startId,
        end: endId,
        shapeId,
      });
    } else {
      setSteps((s) =>
        s.map((step) => {
          if (step.id === selectedStep) {
            return {
              ...step,
              config: {
                ...step.config,
                board: {
                  ...step.config.board,
                  connections: step.config.board.connections.concat({
                    connectionId,
                    start: generateStartConnectionId(start.parentId, start.title),
                    end: generateEndConnectionId(end.parentId, end.title),
                    headSize: 4,
                    tailSize: 4,
                    curveness: 0.5,
                    color: generateHex(),
                    endPosition: ['left', 'right'],
                    startPosition: ['left', 'right'],
                    uniqKey: Math.random(),
                    metadata: {
                      hideArrows: stepData!.type === StepType.COMBINE,
                    },
                  }),
                },
              },
            };
          }
          return step;
        }),
      );
    }
  };
};
