export enum Actions {
  PLAY = 'play',
  PAUSE = 'pause',
  STOP = 'stop',
  FINISH = 'finish',
  REFRESH = 'refresh',
  LOG = 'log',
}

export enum StatusExecute {
  SUCCESS = 'success',
  ERROR = 'error',
  RUNNING = 'running',
}

export enum FlowSolutionConnectionType {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export enum StatusExecuteColor {
  SUCCESS = 'rgb(76 189 107)',
  ERROR = 'rgb(227 69 84)',
  RUNNING = 'rgb(202 163 85)',
}

// TODO REMOVE OLD ABOVE

export enum FlowSolutionState {
  EMPTY = '',
  QUEUED = 'queued',
  PROCESSING = 'processing',
  PAUSED = 'paused',
  STOPPED = 'stopped',
  COMPLETED = 'completed',
  ERROR = 'error',
}

export enum FlowExecutionState {
  QUEUED = 'queued',
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  ERROR = 'error',
  SKIPPED = 'skipped',
  ABANDONED = 'abandoned',
}

export enum ShapeExecutionState {
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  ERROR = 'error',
  SKIPPED = 'skipped',
  ABANDONED = 'abandoned',
}
