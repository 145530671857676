import { Button, Card, CardContent, Menu, MenuItem, Typography, IconButton, CircularProgress } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import classNames from 'classnames';
import { WizardHandlersContext } from 'modules/digital-threads-mapping-wizard/wizard-context/context';
import { memo, useCallback, useContext, useRef, useState } from 'react';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';

import { DraggableShapeProps } from '../../types';
import ColumnCard, { Types } from '../ColumnCard';
import { useStyles } from './styles';

export const MIN_CARD_HEIGHT = 63;
export const ITEM_HEIGHT = 32;
export const PADDING_TOP = 5;

const renderConnectionsShape = (shapeId: string, type: string, cols: string[], horizontal: boolean, classes) => {
  if (cols.length) {
    return (
      <div
        className={classNames(classes.connectionsWrapper, {
          [classes.connectionsWrapperHorizontal]: horizontal,
        })}
      >
        {cols.map((label) => {
          return <div id={`${shapeId}-${type}-${label}`} key={label} className={classes.connectionsElement} />;
        })}
      </div>
    );
  }
};

const SourceDtData = ({ width, height, title, metadata, id: shapeId }: DraggableShapeProps) => {
  const classes = useStyles();

  const { openImportSourceModal, resetInputCard, refreshCard } = useContext(WizardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);

  const columns = metadata?.columns || [];
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const anchorRef = useRef(null);

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleRefresh = async () => {
    setIsLoading(true);
    await refreshCard(shapeId);
    setIsLoading(false);
  };

  const dynamicHeight = PADDING_TOP + MIN_CARD_HEIGHT + ITEM_HEIGHT * columns.length;
  const cardHeight = columns.length ? dynamicHeight : height;

  return (
    <Card className={classes.container} style={{ width, height: cardHeight }}>
      <CardContent className={classes.content}>
        <div className={classes.cardHeader}>
          <Typography variant="h6" className={`${classes.headerItem} ${classes.headerItemText}`}>
            {title}
          </Typography>
          <div className={classes.headerItemMenuWrap}>
            <div ref={anchorRef} />
            {!insidePreview && (
              <IconButton disableRipple className={classes.headerItemMenu} size="small" onClick={handleMenuOpen}>
                <MoreVertIcon className={`${classes.headerItem} ${classes.headerItemMenu}`} />
              </IconButton>
            )}
          </div>
        </div>

        {isLoading ? (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {!columns.length ? (
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  onClick={() => {
                    openImportSourceModal(shapeId);
                  }}
                >
                  Import
                </Button>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, position: 'relative' }}>
                {columns.map((col) => (
                  <ColumnCard type={Types.Start} key={`${shapeId}-${col}`} parentId={shapeId} title={col} />
                ))}
                {renderConnectionsShape(shapeId, 'start', columns, true, classes)}
              </div>
            )}
          </>
        )}
      </CardContent>
      <Menu anchorEl={anchorRef.current} open={isMenuOpen} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            resetInputCard(shapeId);
            handleMenuClose();
          }}
        >
          Reset
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleRefresh();
            handleMenuClose();
          }}
        >
          Refresh
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default memo(SourceDtData);
