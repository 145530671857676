import { memo, useContext, useState } from 'react';
import DraggableLayout from 'modules/shared/components/DraggableLayout';
import Menu from 'modules/flow-solution-builder/builder-board-ui/Menu';
import { useBuilderContextMenuState } from 'modules/shared/hooks/base';
import { WizardContext, WizardHandlersContext } from 'modules/digital-threads-mapping-wizard/wizard-context/context';

import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { useAdminOrSuperUser } from 'modules/auth/tools';
import UpdateCombineColumnModalUi from '../../../combine-column-modal-ui/update-combine-column-modal';
import ImportSourceModalUi from '../../../import-source-modal-ui';

const Combine = () => {
  const { stepData, layoutRef, connection, projectId } = useContext(WizardContext);
  const { updateShape, deleteConnectionChain } = useContext(WizardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);
  const editable = useAdminOrSuperUser(connection, projectId);

  const { menuConfig, handleMenuClose, openMenu } = useBuilderContextMenuState();

  const [options, setOptions] = useState<{ title: string; onClick: () => void }[]>([]);

  return (
    <>
      <DraggableLayout
        bgColour="rgb(255, 255, 255)"
        loadingBgColor="rgba(255, 255, 255, 0.2)"
        isLoading={false}
        boardData={stepData!.config.board}
        disableAdaptLayout
        adaptOnMount
        cardDragEnabled={!insidePreview}
        initial={stepData!.config.position}
        layoutRef={layoutRef}
        shapeProps={
          insidePreview || !editable
            ? {}
            : {
                onUpdate: updateShape,
              }
        }
        editableConnectionProps={
          insidePreview || !editable
            ? {}
            : {
                passProps: {
                  computedProps: (id) => {
                    return {
                      style: {
                        cursor: 'context-menu',
                      },
                      onContextMenu: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const { clientX, clientY } = e;
                        const layoutDimensions = layoutRef.current.containerEl!.getBoundingClientRect();
                        const config = {
                          x: clientY,
                          y: clientX,
                          id,
                          shapeX: clientX - layoutDimensions.x,
                          shapeY: clientY - layoutDimensions.y,
                        };

                        openMenu(config);

                        setOptions([
                          {
                            title: 'Delete Chain',
                            onClick: () => {
                              deleteConnectionChain(config.id);

                              handleMenuClose();
                            },
                          },
                        ]);
                      },
                    };
                  },
                },
              }
        }
      />
      {!insidePreview && editable && (
        <>
          <ImportSourceModalUi />

          <UpdateCombineColumnModalUi />

          <Menu {...menuConfig} onClose={handleMenuClose} items={options} />
        </>
      )}
    </>
  );
};

export default memo(Combine);
