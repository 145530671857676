import { gql } from '@apollo/client';

export const FLOW_SOLUTION_EXECUTION_SUBSCRIBTION = gql`
  subscription FlowSolutionExecutionSubscribtion($userId: String!, $executionId: String!, $connection: String!) {
    flowSolutionExecutionSubscribtion(user_id: $userId, execution_id: $executionId, connection: $connection) {
      executionId
      type
      entityId
      status
      completedAt
      metadata
    }
  }
`;

export const FLOW_EXECUTION_ISSUES_UPDATED_SUBSCRIBTION = gql`
  subscription executionIssuesUpdated($projectId: String!, $connection: String!) {
    executionIssuesUpdated(project_id: $projectId, connection: $connection) {
      logId
      issues {
        issueId
        issueInfo
      }
    }
  }
`;

export const FLOW_SOLUTION_UPDATES_SUBSCRIBTION = gql`
  subscription flowSolutionUpdatesSubscribtion(
    $projectId: String!
    $connection: String!
    $userId: String!
    $boardId: String!
  ) {
    flowSolutionUpdatesSubscribtion(
      project_id: $projectId
      user_id: $userId
      board_id: $boardId
      connection: $connection
    ) {
      type
      metadata
      boardId
    }
  }
`;
