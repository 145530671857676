import { Card, IconButton, Menu, MenuItem, Box, Collapse } from '@mui/material';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { memo, useCallback, useContext, useState, useRef } from 'react';
import { WizardHandlersContext } from 'modules/digital-threads-mapping-wizard/wizard-context/context';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
// eslint-disable-next-line max-len
import PreviewConditionModalUi from 'modules/digital-threads-mapping-wizard/transformation-modal-ui/preview-condition-modal';
import { DraggableShapeProps } from '../../types';
import { useStyles } from './styles';

interface ConditionCardProps extends DraggableShapeProps {
  conditionType: 'if' | 'ifElse';
}

const ConditionCard = ({ id, metadata }: ConditionCardProps) => {
  const conditionType = metadata?.conditionType || 'if';
  const classes = useStyles({ conditionType });

  const { deleteCondition, openUpdateConditionModal } = useContext(WizardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const handleMenuOpen = useCallback(() => setMenuOpen(true), []);
  const handleMenuClose = useCallback(() => setMenuOpen(false), []);
  const handleExpandClick = useCallback(
    (index: number) => setExpandedIndex(expandedIndex === index ? null : index),
    [expandedIndex],
  );

  const [isModalOpen, setModalOpen] = useState(false);
  const handleModalOpen = useCallback(() => setModalOpen(true), []);
  const handleModalClose = useCallback(() => setModalOpen(false), []);
  const menuItem = insidePreview
    ? [
        <MenuItem
          key="view"
          onClick={() => {
            handleModalOpen();
            handleMenuClose();
          }}
        >
          View
        </MenuItem>,
      ]
    : [
        <MenuItem
          key="edit"
          onClick={() => {
            openUpdateConditionModal(id, conditionType);
            handleMenuClose();
          }}
        >
          Edit
        </MenuItem>,
        <MenuItem
          key="delete"
          onClick={() => {
            deleteCondition(id);
            handleMenuClose();
          }}
        >
          Delete
        </MenuItem>,
      ];

  return (
    <Card className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.conditionTypeText}>{conditionType === 'if' ? 'If Condition' : 'Else If Condition'}</div>

        <Box className={classes.conditionList}>
          {metadata?.conditions?.map((condition, conditionIndex) => (
            <div key={condition.id} style={{ marginBottom: '10px' }}>
              <div className={classes.header}>
                <div title={condition.type} className={classes.headerItemText}>
                  {condition.type.charAt(0).toUpperCase() + condition.type.slice(1)}
                </div>
                <IconButton
                  size="small"
                  onClick={() => handleExpandClick(conditionIndex)}
                  className={classes.expandButton}
                >
                  {expandedIndex === conditionIndex ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>

              <Collapse in={expandedIndex === conditionIndex} timeout="auto" unmountOnExit>
                <Box className={classes.ruleContainer}>
                  {condition.rules?.map((rule) => (
                    <div key={rule.id} className={classes.ruleText}>
                      {rule.type}
                    </div>
                  )) || <div className={classes.ruleText}>No rules</div>}
                </Box>
              </Collapse>
            </div>
          ))}
        </Box>
      </div>

      <div className={classes.headerItemMenuContainer}>
        <div ref={anchorRef} />
        <IconButton disableRipple className={classes.headerItemMenu} size="small" onClick={handleMenuOpen}>
          <MoreVertIcon className={classes.headerItemMenu} />
        </IconButton>
      </div>

      <Menu anchorEl={anchorRef.current} open={isMenuOpen} onClose={handleMenuClose}>
        {menuItem}
      </Menu>
      {insidePreview && metadata && (
        <PreviewConditionModalUi isOpen={isModalOpen} conditions={metadata.conditions || []} close={handleModalClose} />
      )}
    </Card>
  );
};

export default memo(ConditionCard);
