import { memo, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useFlowsByProjectsList } from 'modules/shared/hooks/api/flows-by-project-list';
import { useUpdateFlowSolutionShapeData } from 'modules/project-flow-solution/hooks';

import { useSearch } from 'modules/shared/hooks/url';
import { StepExecuteContext } from 'modules/project-flow-solution/board-ui/contexts/StepExecuteContext';
import { FlowSolutionState } from 'modules/project-flow-solution/board-ui/enums';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { useAdminOrSuperUser, useEndUserOrHigher } from 'modules/auth/tools';
import EditCardModal from './EditCardModal';
import { DraggableShapeProps } from '../types';

import CardUi from './CardUi';
import useHookStyles from './hooks/useHookStyles';
import { LayoutContext } from '../../DraggableLayout/context/LayoutContext';

const POCCard = ({ id, title, scale, metadata = {}, boardId, onUpdate, onDelete }: DraggableShapeProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const { set } = useSearch();

  const { projectId, connection } = useProjectInfoFromSearch();

  const { loading: cardSaving, update } = useUpdateFlowSolutionShapeData(boardId, projectId, connection);

  const {
    loading: flowsLoading,
    flows,
    refetch,
    error: flowsError,
  } = useFlowsByProjectsList(projectId, connection, true);

  const { playBoard, executionDetails, shapeSelection, setShapeSelection } = useContext(StepExecuteContext);

  const styleCardUI = useHookStyles(shapeSelection, id, executionDetails);

  const { isBuilder } = useContext(LayoutContext);

  const {
    dateActual,
    datePlan,
    actionable,
    dataspaceLink = '',
    flowLink = '',
    docLink = '',
    flowsToExecute = [],
    isRunSequentially,
  } = metadata || {};

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
    set({ modalId: '' });
  }, [set]);

  const handleOpenEditModal = useCallback(() => {
    setIsModalOpen(true);
    set({ modalId: id });
    setMenuOpen(false);
  }, [id, set]);

  const handleCloseEditModal = useCallback(() => {
    setIsModalOpen(false);
    set({ modalId: '' });
  }, [set]);

  const handleDelete = useCallback(() => {
    onDelete?.(id);
  }, [id, onDelete]);

  const handleUpdate = useCallback(
    (close, data) => {
      const { shapeId, ...rest } = data;

      onUpdate?.(shapeId, rest);

      close();
    },
    [onUpdate],
  );

  const handleFlowSelect = () => {
    if (shapeSelection === id) {
      setShapeSelection?.('');
      return;
    }
    setShapeSelection?.(id);
  };

  const editable = useAdminOrSuperUser(connection, projectId);
  const runnable = useEndUserOrHigher(connection, projectId);

  const readOnlyCardActions =
    !isBuilder &&
    ![
      FlowSolutionState.COMPLETED,
      FlowSolutionState.EMPTY,
      FlowSolutionState.ERROR,
      FlowSolutionState.STOPPED,
    ].includes(executionDetails.currentState);

  const menu = (
    <>
      <div ref={anchorRef} />
      <Menu anchorEl={anchorRef.current} open={isMenuOpen} onClose={handleMenuClose}>
        <MenuItem onClick={handleOpenEditModal}>{readOnlyCardActions ? 'View' : 'Edit'}</MenuItem>
        {onDelete && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
      </Menu>
    </>
  );

  const actualDate = useMemo(() => {
    if (!executionDetails.currentState) {
      return dateActual;
    }
    return executionDetails.shapeStatuses.find((s) => s.shapeId === id)?.completedAt;
  }, [dateActual, executionDetails, id]);

  return (
    <>
      <CardUi
        id={id}
        scale={scale}
        title={title}
        dateActual={actualDate}
        datePlan={datePlan}
        onMenuClick={handleMenuOpen}
        menu={editable ? menu : undefined}
        actionable={actionable}
        actionableProps={{
          dataspaceLink,
          flowLink,
          docLink,
          disableExecFlows: readOnlyCardActions || !runnable || !flowsToExecute.length,
          onFlowsExecute: () => playBoard(id),
        }}
        containerStyle={styleCardUI}
        onClick={handleFlowSelect}
      />
      <EditCardModal
        key={isModalOpen.toString()}
        open={isModalOpen}
        title={title}
        shapeId={id}
        dataspaceLink={dataspaceLink}
        flowLink={flowLink}
        docLink={docLink}
        flowsToExecute={flowsToExecute}
        datePlan={datePlan}
        dateActual={dateActual}
        actionable={actionable}
        flowsLoading={flowsLoading}
        cardSaving={cardSaving}
        onUpdateCard={onUpdate ? handleUpdate : update}
        flows={flows}
        flowsError={flowsError}
        onClose={handleCloseEditModal}
        isRunSequentially={isRunSequentially}
        refetch={refetch}
        readOnly={readOnlyCardActions}
      />
    </>
  );
};

export default memo(POCCard);
