import { Card, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { memo, useCallback, useContext, useRef, useState } from 'react';
import { WizardHandlersContext } from 'modules/digital-threads-mapping-wizard/wizard-context/context';

import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import PreviewModal from 'modules/digital-threads-mapping-wizard/transformation-modal-ui/preview-transformation-modal';
import { DraggableShapeProps } from '../../types';
import { useStyles } from './styles';

const TransformationCard = ({ width, height, title, id, metadata }: DraggableShapeProps) => {
  const classes = useStyles();

  const { deleteTransformation, openUpdateTransformationModal } = useContext(WizardHandlersContext);
  const { insidePreview } = useContext(PreviewContext);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const menuItem = insidePreview
    ? [
        <MenuItem
          key="view"
          onClick={() => {
            handleModalOpen();
            handleMenuClose();
          }}
        >
          View
        </MenuItem>,
      ]
    : [
        <MenuItem
          key="edit"
          onClick={() => {
            openUpdateTransformationModal(id);
            handleMenuClose();
          }}
        >
          Edit
        </MenuItem>,
        <MenuItem
          key="delete"
          onClick={() => {
            deleteTransformation(id);
            handleMenuClose();
          }}
        >
          Delete
        </MenuItem>,
      ];

  return (
    <Card className={classes.container} style={{ width, height }}>
      <div title={title} className={`${classes.headerItem} ${classes.headerItemText}`}>
        {title}
      </div>
      <div className={classes.headerItemMenuContainer}>
        <div ref={anchorRef} />
        <IconButton disableRipple className={classes.headerItemMenu} size="small" onClick={handleMenuOpen}>
          <MoreVertIcon className={`${classes.headerItem} ${classes.headerItemMenu}`} />
        </IconButton>
      </div>

      <Menu anchorEl={anchorRef.current} open={isMenuOpen} onClose={handleMenuClose}>
        {menuItem}
      </Menu>
      {insidePreview && metadata && (
        <PreviewModal isOpen={isModalOpen} type={metadata.type} config={metadata.config} close={handleModalClose} />
      )}
    </Card>
  );
};

export default memo(TransformationCard);
