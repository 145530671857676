import { gql } from '@apollo/client';

export const UPDATE_FLOW_SOLUTION_SHAPE_DATA = gql`
  mutation UpdateFlowSolutionShapeData(
    $projectId: String!
    $boardId: String!
    $shapeId: String!
    $title: String
    $metadata: String
    $connection: String!
  ) {
    updateFlowSolutionShape(
      shape_id: $shapeId
      project_id: $projectId
      board_id: $boardId
      title: $title
      metadata: $metadata
      connection: $connection
    ) {
      shapeId
      title
      xCoordinate
      yCoordinate
      metadata
      width
      height
      type
    }
  }
`;

export const UPDATE_FLOW_SOLUTION_SHAPE_METADATA = gql`
  mutation UpdateFlowSolutionShapeMetadata(
    $title: String!
    $boardId: String!
    $projectId: String!
    $shapeId: String!
    $metadata: String
    $connection: String!
  ) {
    updateFlowSolutionShape(
      title: $title
      shape_id: $shapeId
      board_id: $boardId
      board_id: $boardId
      metadata: $metadata
      connection: $connection
    ) {
      shapeId
      title
      xCoordinate
      yCoordinate
      metadata
      width
      height
      type
    }
  }
`;

export const DELETE_FLOW_SOLUTION = gql`
  mutation DeleteFlowSolution($solutionId: String!, $projectId: String!, $connection: String!) {
    deleteFlowSolution(solution_id: $solutionId, project_id: $projectId, connection: $connection)
  }
`;

export const UPDATE_FLOW_SOLUTION_CHECKLIST_SHAPE = gql`
  mutation UpdateFlowSolutionChecklistShape(
    $connection: String!
    $projectId: String!
    $boardId: String!
    $shapeId: String!
    $title: String!
    $metadata: String!
    $checklistItems: [ChecklistItem!]!
  ) {
    updateFlowSolutionChecklistShape(
      connection: $connection
      project_id: $projectId
      shape_id: $shapeId
      board_id: $boardId
      title: $title
      metadata: $metadata
      checklist_items: $checklistItems
    ) {
      shapeId
      title
      xCoordinate
      yCoordinate
      metadata
      width
      height
      type
    }
  }
`;

export const UPDATE_FLOW_SOLUTION_CHECKLIST_ITEMS_WITH_STATE = gql`
  mutation UpdateFlowSolutionChecklistItemsWithState(
    $connection: String!
    $projectId: String!
    $executionId: String!
    $checklistItems: [ChecklistItemStatus!]!
  ) {
    updateFlowSolutionChecklistItemsWithState(
      connection: $connection
      project_id: $projectId
      execution_id: $executionId
      checklist_items: $checklistItems
    )
  }
`;

export const UPDATE_BOARD_SCHEDULER = gql`
  mutation UpdateBoardScheduler($connection: String!, $scheduler: BoardScheduler!) {
    updateBoardScheduler(connection: $connection, scheduler: $scheduler) {
      schedulerId
      isActive
      schedulerType
      schedulerLabel
      schedulerPattern
    }
  }
`;

export const UPDATE_NOTEBOOK_BY_BOARD_ID = gql`
  mutation UpdateNoteBookByBoardId($connection: String!, $projectId: String!, $boardId: String!, $content: String) {
    updateNotebook(connection: $connection, project_id: $projectId, board_id: $boardId, content: $content) {
      content
    }
  }
`;

export const COPY_FLOW_SOLUTIONS = gql`
  mutation copyFlowSolutions($source: ProjectDetailsInput!, $target: ProjectDetailsInput!, $flowSolutions: [String!]!) {
    copyFlowSolutions(source: $source, target: $target, flow_solutions: $flowSolutions)
  }
`;

export const START_FLOW_SOLUTION = gql`
  mutation startFlowSolution($connection: String!, $boardId: String!, $shapeId: String) {
    startFlowSolution(connection: $connection, board_id: $boardId, shape_id: $shapeId)
  }
`;

export const STOP_FLOW_SOLUTION = gql`
  mutation stopFlowSolution($connection: String!, $projectId: String!, $executionId: String!) {
    stopFlowSolution(connection: $connection, project_id: $projectId, execution_id: $executionId)
  }
`;

export const PAUSE_FLOW_SOLUTION = gql`
  mutation pauseFlowSolution($connection: String!, $projectId: String!, $executionId: String!) {
    pauseFlowSolution(connection: $connection, project_id: $projectId, execution_id: $executionId)
  }
`;

export const PROCEED_FLOW_SOLUTION = gql`
  mutation proceedFlowSolution($connection: String!, $projectId: String!, $executionId: String!) {
    proceedFlowSolution(connection: $connection, project_id: $projectId, execution_id: $executionId)
  }
`;

export const RESET_FLOW_SOLUTION = gql`
  mutation resetFlowSolution($connection: String!, $projectId: String!, $boardId: String!) {
    resetFlowSolution(connection: $connection, board_id: $boardId, project_id: $projectId)
  }
`;

export const CHOOSE_FLOW_SOLUTION_EXECUTION_THREAD = gql`
  mutation chooseFlowSolutionExecutionThread(
    $connection: String!
    $executionId: String!
    $shapeId: String!
    $choice: String!
  ) {
    chooseFlowSolutionExecutionThread(
      connection: $connection
      execution_id: $executionId
      shape_id: $shapeId
      choice: $choice
    )
  }
`;
